const state = {
    description: "",
    displaySecond: 10
}

const mutations = {
    setState(state, data) {
        state.displaySecond = data.displaySecond;
        state.description = data.description;
    },
    setDisplaySecond(state, displaySecond) {
        state.displaySecond = displaySecond;
    },
    setDescription(state, description) {
        state.description = description;
    },
}

export default {
    namespaced: true,
    state,
    mutations
}