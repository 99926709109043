import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel
} from "@microsoft/signalr";
import jwtHelper from "./shared/jwt-helper";
import storage from "./storage/index";
import accountStorage from "./storage/modules/account";
import contentStorage from "./storage/modules/content";
import cacheHelper from "./shared/cache-helper";
import store from "./store/index";
import utils from "./shared/utils";

// managers
import contentManager from './manager/modules/content-manager';
import playlistManager from './manager/modules/playlist-manager';
import accountClientManager from './manager/modules/account-client-manager';
import accountManager from './manager/modules/account-manager';
import trafficMapManager from './manager/modules/traffic-map-manager';
import currencyManager from './manager/modules/currency-manager';
import weatherManager from './manager/modules/weather-manager';
import footerHeaderManager from './manager/modules/footer-header-manager';
import prayerTimesManager from './manager/modules/prayer-times-manager';
import pharmacyManager from './manager/modules/pharmacy-manager';
import newsManager from './manager/modules/news-manager';
import fixtureManager from './manager/modules/fixture-manager';
import scoreBoardManager from './manager/modules/score-board-manager';
import instagramManager from './manager/modules/instagram-manager';
import advertisementManager from './manager/modules/advertisement-manager';
import dailyHoroscopeManager from './manager/modules/daily-horoscope-manager';
import todayInHistoryManager from './manager/modules/today-in-history-manager';
import foursquareTipManager from './manager/modules/foursquare-tip-manager';
import checkinManager from './manager/modules/checkin-manager';
import userManager from './manager/modules/user-manager';

async function loadDatabase() {
  try {
    window.console.log("Hub Connected. Db is loading.");
    await playlistManager.downloadPlaylists();
    window.console.log("downloadPlaylists Finished");
    await accountClientManager.downloadAccountClient();
    window.console.log("downloadAccountClient Finished");
    await accountManager.downloadAccount();
    window.console.log("downloadAccount Finished");
    await currencyManager.downloadCurrencyValues();
    window.console.log("downloadCurrencyValues Finished");
    footerHeaderManager.downloadFooterHeaderContents();
    window.console.log("downloadFooterHeaderContents Finished");
    await fixtureManager.downloadFixture();
    window.console.log("downloadFixture Finished");
    await scoreBoardManager.downloadScoreboard();
    window.console.log("downloadScoreboard Finished");
    await dailyHoroscopeManager.downloadDailyHoroscopes();
    window.console.log("downloadDailyHoroscopes Finished");
    await todayInHistoryManager.downloadTodayInHistories();
    window.console.log("downloadTodayInHistories Finished");
    await newsManager.downloadNews();
    window.console.log("downloadNews Finished");
    await foursquareTipManager.downloadFoursquareTips();
    window.console.log("downloadFoursquareTips Finished");
    await advertisementManager.downloadNextAdvertisement();
    window.console.log("downloadNextAdvertisement Finished");
    await checkinManager.downloadFoursquareCheckin();
    window.console.log("downloadCheckins Finished");
    await contentManager.downloadImageFontsAndPositionsAndBackgrounds();
    await contentManager.downloadContents();
    window.console.log("downloadContents Finished");
    await instagramManager.downloadInstagramContents();
    window.console.log("downloadInstagramContents Finished");

  } catch (err) {
    window.console.log(err);
  }

}

async function loadPalylistChangedDatabase() {
  try {
    window.console.log("Playlist changed loading db ...");
    await playlistManager.downloadPlaylists();
    window.console.log("downloadPlaylists Finished");
    await fixtureManager.downloadFixture();
    window.console.log("downloadFixture Finished");
    await scoreBoardManager.downloadScoreboard();
    window.console.log("downloadScoreboard Finished");
    await dailyHoroscopeManager.downloadDailyHoroscopes();
    window.console.log("downloadDailyHoroscopes Finished");
    await todayInHistoryManager.downloadTodayInHistories();
    window.console.log("downloadTodayInHistories Finished");
    await newsManager.downloadNews();
    window.console.log("downloadNews Finished");
    await foursquareTipManager.downloadFoursquareTips();
    window.console.log("downloadFoursquareTips Finished");
    await advertisementManager.downloadNextAdvertisement();
    window.console.log("downloadNextAdvertisement Finished");
    await checkinManager.downloadFoursquareCheckin();
    window.console.log("downloadCheckins Finished");
    await contentManager.downloadImageFontsAndPositionsAndBackgrounds();
    await contentManager.downloadContents();
    window.console.log("downloadContents Finished");
    await instagramManager.downloadInstagramContents();
    window.console.log("downloadInstagramContents Finished");

  } catch (error) {
    window.console.log(error);
  }

}


var retryDelays = [0];
for (let i = 1; i <= 2000; i++) retryDelays.push(i * 1000);
var connection = null;
var storeLoaded = false;
let startedPromise = null
function start() {
  window.console.info('starting to connect with hub');
  if (!connection.connectionStarted) {
    startedPromise = connection.start()
      .then(() => loadDatabase())
      .catch(err => {
        window.console.error('Failed to connect with hub', err);
        if (!storeLoaded)
          store.dispatch("loadStore");
        if (connection && connection.connectionStarted) {
          return null;
        }
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 10000));
      });
    return startedPromise;
  }
}

export default {
  async disconnectFromHub() {
    if (connection) await connection.stop();
  },

  async install(Vue) {
    try {
      await store.dispatch("setNextPlaylist");
      await jwtHelper.refreshToken();
      var url = process.env.VUE_APP_BASE_API_URL + "/IstMedyaHub";
      connection = new HubConnectionBuilder()
        .withUrl(url, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => localStorage.getItem("token")
        })
        .withAutomaticReconnect(retryDelays)
        .configureLogging(LogLevel.Information)
        .build();

      connection.serverTimeoutInMilliseconds = 1000 * 60 * 60 * 2;
      //-------------------------------------------------------------------//
      //connection.onclose(() => start());
      start();
      //-----------------------------------------------------------------//
      // connection
      //   .start()
      //   .then(() => loadDatabase())
      //   .catch(err => {
      //     window.console.log(err);
      //     // if (err &&
      //     //   (
      //     //     (err.message && err.message.indexOf('Exceeded') !== -1) ||
      //     //     (err.indexOf('Exceeded') !== -1)
      //     //   )
      //     // ) {
      //     //   cacheHelper.clear().then(() => {
      //     //     utils.reloadTwice();
      //     //   });
      //     // }
      //     // else 
      //     //{
      //     store.dispatch("loadStore");
      //     //}
      //   });

      connection.onreconnecting(error =>
        window.console.log("Hub bağlantı denemesi: " + JSON.stringify(error))
      );
      connection.onreconnected(() => loadDatabase());

      const medyaHub = new Vue();
      Vue.prototype.$medyaHub = medyaHub;

      connection.on("ContentAdded", async (contentId) => {
        window.console.log('----------------------- new content added', contentId);
        await contentManager.downloadContentById(contentId);
      });

      connection.on("ContentDeleted", async (contentId) => {
        window.console.log('----------------------- content deleted', contentId)
        await store.dispatch("setNextPlaylist");
        let content = await contentStorage.getContentById(contentId);
        if (content) {
          await contentStorage.deleteContentById(contentId);
          if (content.data && content.data.files) {
            content.data.files.forEach(async file => {
              await cacheHelper.deleteByKey(file.fileName);
            });
          }
        }
      });

      let loading = false;

      connection.on("PlayListChanged", async () => {
        try {
          await setTimeout(async () => {
            if (!loading) {
              loading = true;
              await loadPalylistChangedDatabase();
              loading = false;
            }
          }, 1000);
        } catch (error) {
          window.console.log(error);
        }

      });

      connection.on("AccountClientChanged", () => {
        accountClientManager.downloadAccountClient().then(() => {
          utils.reloadTwice();
        });
      });

      connection.on("AccountChanged", () => {
        accountManager.downloadAccount();
      });

      connection.on("AccountLogoChanged", () => {
        accountManager.downloadAccount();
      });

      connection.on("FooterHeaderChanged", () => {
        footerHeaderManager.downloadFooterHeaderContents();
      });

      connection.on("WeatherChanged", () => {
        var accountId = jwtHelper.getAccountId();
        accountStorage.getAccountById(accountId).then(account => {
          if (account && account.cityId) {
            weatherManager.downloadWeatherForecasts(account.cityId);
          }
        });
      });

      connection.on("TrafficMapChanged", async () => {
        let accountId = jwtHelper.getAccountId();
        let account = await accountStorage.getAccountById(accountId);
        if (account && account.cityId && await playlistManager.checkPlaylistByCategoryName("Trafik Haritası")) {
          await trafficMapManager.downloadTrafficMap(account.cityId);
        }
      });

      connection.on("CurrencyChanged", () => {
        currencyManager.downloadCurrencyValues();
      });

      connection.on("NewsChanged", () => {
        newsManager.downloadNews();
      });

      connection.on("FixtureChanged", () => {
        fixtureManager.downloadFixture();
      });

      connection.on("ScoreboardChanged", () => {
        scoreBoardManager.downloadScoreboard();
      });

      connection.on("InstagramContentChanged", () => {
        instagramManager.downloadInstagramContents();
      });

      connection.on("FourSquareTipChanged", async () => {
        window.console.log("FourSquareTipChanged downloadFoursquareTips Finished");
        await foursquareTipManager.downloadFoursquareTips();
      });

      connection.on("FourSquareCheckInChanged", () => {
        checkinManager.downloadFoursquareCheckin();
      });


      connection.on("DailyHoroscopeChanged", () => {
        dailyHoroscopeManager.downloadDailyHoroscopes();
      });

      connection.on("TodayInHistoryChanged", () => {
        todayInHistoryManager.downloadTodayInHistories();
      });

      connection.on("PharmacyChanged", () => {
        var accountId = jwtHelper.getAccountId();
        accountStorage.getAccountById(accountId).then(account => {
          if (account && account.districtId) {
            pharmacyManager.downloadPharmacies(account.districtId);
          }
        });
      });

      connection.on("PrayerTimeChanged", () => {
        var accountId = jwtHelper.getAccountId();
        accountStorage.getAccountById(accountId).then(account => {
          if (account && account.cityId) {
            prayerTimesManager.downloadPrayerTimes(account.cityId);
          }
        });
      });

      connection.on("ResetCache", () => {
        cacheHelper.clear().then(() => {
          utils.reloadTwice();
        });
      });

      connection.on("ResetIndexedDb", () => {
        storage.deleteDb().then(() => {
          utils.reloadTwice();
        });
      });

      connection.on("Logout", () => {
        userManager.logOut();
      });

      connection.on("RefreshPage", () => {
        utils.reloadTwice();
      });

      connection.on("StartCampaign", (product) => {
        store.dispatch("product/addToList", JSON.parse(product));
      });
      connection.on("StopCampaign", (id) => {
        store.commit("product/removeFromList", parseInt(id));
      });

      connection.on("StartBirthday", (model) => {
        store.dispatch("birthday/start", JSON.parse(model));
      });

      connection.on("StartWelcome", (model) => {
        window.console.log('-------------------------------',model)
        store.dispatch("welcome/start", JSON.parse(model));
      });

      connection.on("StartLuckyTable", (item) => {
        store.dispatch("luckyTable/start", JSON.parse(item));
      });
      connection.on("StopLuckyTable", () => {
        store.commit("luckyTable/stop");
      });

      connection.on("VersionChanged", () => {
        // check version
        utils.checkVersion();
      });
    }
    catch (ex) {
      window.console.log(ex);
      await store.dispatch("setNextPlaylist");
    }
  }
};