import utility from "./utils";
import storage from '../storage/modules/content';

export function checkContentTime(content) {
  var startHour = content.startTime.split(":")[0];
  var startMinute = content.startTime.split(":")[1];
  var startDay = new Date(content.startDate);
  startDay.setHours(startHour);
  startDay.setMinutes(startMinute);

  var endHour = content.endTime.split(":")[0];
  var endMinute = content.endTime.split(":")[1];
  var endDay = new Date(content.endDate);
  endDay.setHours(endHour);
  endDay.setMinutes(endMinute);

  var today = new Date();
  return startDay <= today && endDay >= today;
}

export async function getOneTimeContent() {
  let list = await storage.getContents();
  var data = null;
  await utility.asyncForEach(list, async c => {
    var content = c.data;
    if (
      content.schedule &&
      content.schedule.frequenceType &&
      content.schedule.frequenceType === "On" &&
      checkContentTime(content)
    ) {
      data = content;
      return;
    }
  });
  return data;
}

export async function sortArrayByFileSize(contents) {
  await utility.asyncForEach(contents, async f => {
    if (f && f.data && f.data.files && f.data.files.length) {
      let sum = f.data.files
        .map(o => o.fileSizeKb)
        .reduce((a, c) => {
          return (a || 0) + (c.fileSizeKb || 0);
        });
      f.sum = sum;
    } else {
      f.sum = 0;
    }
  });
  contents = contents.sort((a, b) => (a.sum < b.sum ? -1 : 1));
  return contents;
}

export default { checkContentTime, getOneTimeContent, sortArrayByFileSize };
