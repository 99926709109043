import http from "../../shared/http";
import newsStorage from '../../storage/modules/news';
import { checkPlaylistByCategoryName } from './playlist-manager'
import utility from "../../shared/utils";
import cacheHelper, { addFileToCache } from "../../shared/cache-helper";

export async function downloadNews() {
    if (!(await checkPlaylistByCategoryName("Haber"))) {
        await newsStorage.clearNews();
        await clearUnUsedNewsCaches();
        return;
    }
    let news = await http.get("news/getactivelist");
    if (news) {
        let oldList = await newsStorage.getNews();
        await newsStorage.clearNews();
        await utility.asyncForEach(news, async c => {
            let filteredList = oldList
                ? oldList.filter(u => u.categoryName === c.categoryName)
                : null;
            let minViewCount =
                filteredList && filteredList.length ? filteredList[0].viewCount : 0;
            let oldItem =
                oldList && oldList.length ? oldList.find(u => u.guid == c.guid) : null;
            c.viewCount = oldItem ? oldItem.viewCount : minViewCount;
            await newsStorage.addNews(c);
        });
        let newsList = await newsStorage.getNews();
        await clearUnUsedNewsCaches(newsList);
        await saveNewsToCache(newsList);
    }
}

export async function clearUnUsedNewsCaches(news) {
    let fileNames = [];
    if (news && news.length) {
        news.forEach(n => fileNames.push(process.env.VUE_APP_BASE_CDN_URL + "/" + n.fileName));
    }

    let cacheList = await cacheHelper.getAllFiles();
    if (cacheList) {
        await utility.asyncForEach(cacheList, async f => {
            if (f.url.includes("Resources/News") && !fileNames.includes(f.url)) {
                await cacheHelper.deleteByKey(f.url);
            }
        });
    }
}

export async function saveNewsToCache(news) {
    if (!news || !news.length) return;
    await utility.asyncForEach(news, async n => {
        if (n && n.fileName) {
            let fileName = process.env.VUE_APP_BASE_CDN_URL + "/" + n.fileName;
            await addFileToCache(fileName);
        }
    });
}

export default {
    downloadNews
}