import http from "../../shared/http";
import pharmacyStorage from '../../storage/modules/pharmacy';
import store from "../../store/index";

export async function downloadPharmacies(districtId) {
    let pharmacies = await http.get(`pharmacies/getlistbydistrictid?districtId=${districtId}`);
    if (pharmacies) {
        await pharmacyStorage.clearPharmacies();
        await pharmacyStorage.bulkAddPharmacy(pharmacies);
        await store.dispatch("pharmacy/loadPharmacies");
    }
}

export default {
    downloadPharmacies
}