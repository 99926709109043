const cacheName = "v6::pages";

export async function exist(fileName) {
  var cache = await caches.open(cacheName);
  if (cache) {
    var keys = await cache.keys();
    if (keys) {
      var filtered = keys.filter(f => f.url.indexOf(fileName) !== -1);
      return filtered && filtered.length;
    }
  }
  return false;

}

export async function clear() {
  return await caches.delete(cacheName);
}

export async function getAllFiles() {
  var cache = await caches.open(cacheName);
  if (cache) {
    return await cache.keys();
  }
  else
    return null;

}

export async function add(url) {
  let response = await fetch(url);
  if (!response.ok) {
    window.console.error("dosya eklenmedi", url);
    window.console.error(response);
    return false;
  }

  let cache = await caches.open(cacheName);
  if (cache) {
    await cache.put(url, response.clone());
    return true;
  }
  return false;
}

export async function deleteByKey(fileName) {
  //window.console.log('deleting file : ', fileName);
  let cache = await caches.open(cacheName);
  if (cache) {
    if (!fileName.startsWith('http'))
      fileName = process.env.VUE_APP_BASE_CDN_URL + "/" + fileName;
    if (await exist(fileName)) {
      await cache.delete(fileName);
      //window.console.log(deleted ? "silindi" : "silinemedi");
    }
    else {
      //window.console.log('deleting failed not found file : ', fileName);
    }
  }
}

export async function addFileToCache(fileName) {
  if (!fileName) return;
  let exists = await exist(fileName);
  if (!exists) {
    var result = await add(fileName);
    if (!result) window.console.error(fileName + " Cache'e eklenemedi.");
  }
}

export default {
  exist,
  clear,
  getAllFiles,
  deleteByKey,
  add,
  addFileToCache
};
