import storage from "../../storage/modules/foursquare-tip";
import cacheHelper from "../../shared/cache-helper";
import utility from "../../shared/utils";
const state = {
  fullName: "",
  comment: "",
  profileImage: "",
  image: "",
  likeCount: 0,
  typeId: 1
};

const mutations = {
  setState(state, data) {
    state.fullName = data.fullName;
    state.comment = data.comment;
    state.profileImage = data.profileImage;
    state.image = data.image;
    state.likeCount = data.likeCount;
    state.typeId = data.typeId;
  }
};

const actions = {
  async setContent({ dispatch }) {
    let list = await storage.getFourSquareTips();
    if (!list || !list.length) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    let item = null;
    try {
      await utility.asyncForEach(list, async f => {
        let cacheExistsProfile =
          !f.profileImage || !f.profileImage.length || await cacheHelper.exist(f.profileImage);

        let cacheExistsImage =
          !f.image || !f.image.length || await cacheHelper.exist(f.image);
          
        if (cacheExistsProfile && cacheExistsImage) {
          item = f;
          throw 'found';
        }
      });
    } catch (e) {
      //if (e !== 'found') throw e;
    }

    if (!item) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    await storage.updateFourSquareTipViewCount(item.id);
    dispatch("prepareContent", item);
  },
  prepareContent({ dispatch, commit }, content) {
    commit("setState", {
      fullName: content.fullName,
      comment: content.comment,
      profileImage: content.profileImage,
      image: content.image,
      likeCount: content.likeCount,
      typeId: content.typeId
    });
    if (content.image && content.image.length) {
      commit(
        "backgroundImage/setState",
        {
          fileName: content.image,
          description: "",
          displaySecond: 20
        },
        { root: true }
      );
    }
    dispatch("play", "FoursquareTip", { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
