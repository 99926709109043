import storage from "../../storage/modules/today-in-history";

const state = {
  description: "",
  eventDate: ""
};

const mutations = {
  setState(state, data) {
    state.description = data.description;
    state.eventDate = data.eventDate;
  }
};

const actions = {
  async setContent({ dispatch, commit }) {
    let item = await storage.getFirstTodayInHistoryOrderByViewCount();
    if (!item || !item.description) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }

    await storage.updateTodayInHistoryViewCount(item.id, item.viewCount + 1);
    
    commit("setState", {
      description: item.description,
      eventDate: item.eventDate
    });

    dispatch("play", "TodayInHistory", { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
