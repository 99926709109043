import http from "../../shared/http";
import dailyHoroscopeStorage from '../../storage/modules/daily-horoscope';
import utility from "../../shared/utils";
import { checkPlaylistByCategoryName } from './playlist-manager'

export async function downloadDailyHoroscopes() {
    if (!(await checkPlaylistByCategoryName("Burçlar"))) {
        await dailyHoroscopeStorage.clearDailyHoroscopes();
        return;
    }
    let contents = await http.get("dailyHoroscopes/getlist");
    if (contents) {
        let oldList = await dailyHoroscopeStorage.getDailyHoroscopes();
        await dailyHoroscopeStorage.clearDailyHoroscopes();
        await utility.asyncForEach(contents, async c => {
            let minViewCount = oldList && oldList.length ? oldList[0].viewCount : 0;

            let oldItem =
                oldList && oldList.length ? oldList.find(u => u.name == c.name) : null;
            c.viewCount = oldItem ? oldItem.viewCount : minViewCount;
            await dailyHoroscopeStorage.addDailyHoroscope(c);
        });
    }
}

export default {
    downloadDailyHoroscopes
}