import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import store from './store/index'
import './registerServiceWorker'

Vue.use(BootstrapVue)
Vue.config.productionTip = false
// Vue.config.errorHandler = function (err, vm, info) {
//   // `info` is a Vue-specific error info, e.g. which lifecycle hook
//   // the error was found in. Only available in 2.2.0+
//   window.console.log(err);
//   window.console.log(vm);
//   window.console.log(info);
//   navigator.webkitTemporaryStorage.queryUsageAndQuota (
//     function(usedBytes, grantedBytes) {
//         window.console.log('we are using ', usedBytes, ' of ', grantedBytes, 'bytes');
//     },
//     function(e) { window.console.log('Error', e);  }
// );
// }
new Vue({
  render: h => h(App),
  router,
  store,
  components: { App }
}).$mount('#app')