import storage from "../../storage/modules/scoreboard";
import utility from "../../shared/utils";

const state = {
  list: []
};

const mutations = {
  setList(state, list) {
    state.list = list;
  }
};

const actions = {
  async loadScoreboard({  dispatch, commit }) {
    let scoreboards = await storage.getScoreboardByLeagueType(1);
    if (!scoreboards || !scoreboards.length) return;
    await utility.asyncForEach(scoreboards, async f => {
      f.teamImage = await dispatch("fixture/getImageByTeamName", f.teamName, { root: true });
    });
    commit("setList", scoreboards);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
