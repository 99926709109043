import { getDb as db } from "../index";

export function addWeatherForecast(data) {
    return db().weatherForecasts.put({
        id: data.id,
        cityId: data.cityId,
        cityName: data.cityName,
        districtId: data.districtId,
        districtName: data.districtName,
        degrees: data.degrees,
        forecast: data.forecast,
        forecastKey: data.forecastKey,
        lastUpdateDate: data.lastUpdateDate
    });
}

export async function bulkAddWeatherForecast(list){
    var items = list.map(data => ({
        id: data.id,
        cityId: data.cityId,
        cityName: data.cityName,
        districtId: data.districtId,
        districtName: data.districtName,
        degrees: data.degrees,
        forecast: data.forecast,
        forecastKey: data.forecastKey,
        lastUpdateDate: data.lastUpdateDate
    }));
    await db().weatherForecasts.bulkAdd(items);
}

export async function clearWeatherForecasts() {
    await db().weatherForecasts.clear();
}

export function deleteWeatherForecastById(id) {
    return db().weatherForecasts.delete(id);
}

export function getWeatherForecastsByCityId(cityId) {
    return db().weatherForecasts.where("cityId").equals(cityId).toArray();
}

export function getWeatherForecastByDistrictId(districtId) {
    return db().weatherForecasts.where("districtId").equals(districtId).toArray();
}

export function getWeatherForecasts() {
    return db().weatherForecasts.orderBy('id').toArray();
}

export default{
    addWeatherForecast,
    clearWeatherForecasts,
    deleteWeatherForecastById,
    getWeatherForecastsByCityId,
    getWeatherForecastByDistrictId,
    getWeatherForecasts,
    bulkAddWeatherForecast
}