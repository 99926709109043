import { getDb as db } from "../index";

export function addPharmacy(data) {
    return db().pharmacies.put({
        id: data.id,
        cityId: data.cityId,
        districtId: data.districtId,
        pharmacyName: data.pharmacyName,
        address: data.address,
        phone: data.phone,
        lastUpdateDate: data.lastUpdateDate
    });
}

export async function bulkAddPharmacy(list){
    var items = list.map(data => ({
        id: data.id,
        cityId: data.cityId,
        districtId: data.districtId,
        pharmacyName: data.pharmacyName,
        address: data.address,
        phone: data.phone,
        lastUpdateDate: data.lastUpdateDate
    }));
    await db().pharmacies.bulkAdd(items);
}

export async function clearPharmacies() {
    await db().pharmacies.clear();
}

export async function getPharmacyByDistrictId() {
    let accounts = await db().accounts.orderBy('id').toArray();
    if (!accounts || !accounts[0] || !accounts[0].districtId) return null;
    return db().pharmacies.where('districtId').equals(accounts[0].districtId).toArray()
}

export default {
    addPharmacy,
    clearPharmacies,
    getPharmacyByDistrictId,
    bulkAddPharmacy
}
