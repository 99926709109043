import http from "../../shared/http";
import todayInHistoryStorage from '../../storage/modules/today-in-history';
import utility from "../../shared/utils";
import { checkPlaylistByCategoryName } from './playlist-manager'

export async function downloadTodayInHistories() {
    if (!(await checkPlaylistByCategoryName("Tarihte Bugün"))) {
        await todayInHistoryStorage.clearTodayInHistories();
        return;
    }
    let contents = await http.get("todayInHistories/getlist");
    if (contents) {
        let oldList = await todayInHistoryStorage.getTodayInHistories();
        await todayInHistoryStorage.clearTodayInHistories();
        await utility.asyncForEach(contents, async c => {
            let minViewCount = oldList && oldList.length ? oldList[0].viewCount : 0;

            let oldItem =
                oldList && oldList.length ? oldList.find(u => u.id == c.id) : null;
            c.viewCount = oldItem ? oldItem.viewCount : minViewCount;
            await todayInHistoryStorage.addTodayInHistory(c);
        });
    }
}

export default {
    downloadTodayInHistories
}