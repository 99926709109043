import http from "../../shared/http";
import currencyStorage from '../../storage/modules/currency';
import store from "../../store/index";

export async function downloadCurrencyValues() {
    let currencyList = await http.get(`currencies/getlist`);
    if (currencyList) {
        await currencyStorage.clearCurrencies();
        await currencyStorage.bulkAddCurrencies(currencyList);
        await store.dispatch("currency/loadCurrencies");
    }
}

export default {
    downloadCurrencyValues
}