import storage from "../../storage/modules/instagram";
import cacheHelper from "../../shared/cache-helper";
import utility from "../../shared/utils";
const state = {
  description: "",
  likeCount: 0,
  commentsCount: 0,
  userName: "",
  profileImageUrl: "",
  mediaDate: null,
  mediaType: ""
};

const mutations = {
  setState(state, data) {

    var str = data.description;
    if (str && str.length) {
      var arrStr = str.split(" ");
      arrStr.forEach(item => {
        if (item.startsWith("#")) {
          str = str.replace(item, " ");
        }
      });
    }
    state.description = str;
    state.likeCount = data.likeCount;
    state.commentsCount = data.commentsCount;
    state.userName = data.userName;
    state.profileImageUrl = data.profileImageUrl;
    state.mediaDate = data.mediaDate;
    state.mediaType = data.mediaType;
  }
};

const actions = {
  async setContent({ dispatch }, categoryId) {
    let item;
    if (categoryId === 57)
      item = await storage.getFirstInstagramImageContentOrderByViewCount();
    else if (categoryId === 58)
      item = await storage.getFirstInstagramVideoContentOrderByViewCount();
    else dispatch("setNextPlaylist", null, { root: true });

    if (!item) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    if (item.profileImageUrl && item.profileImageUrl.length) {
      item.profileImageUrl = utility.replaceSlashAndBackSlashes(item.profileImageUrl);
    }
    if (item.mediaUrl && item.mediaUrl.length) {
      item.mediaUrl = utility.replaceSlashAndBackSlashes(item.mediaUrl);
    }
    let cacheExists =
      item.mediaUrl &&
      (await cacheHelper.exist(item.mediaUrl));
    if (!cacheExists) {
      item = null;
      var list;
      if (categoryId === 57)
        list = await storage.getInstagramImages();
      else if (categoryId === 58)
        list = await storage.getInstagramVideos();
      try {
        await utility.asyncForEach(list, async f => {
          if (f.mediaUrl && f.mediaUrl.length) {
            f.mediaUrl = utility.replaceSlashAndBackSlashes(f.mediaUrl);
          }
          cacheExists = f.mediaUrl &&
            (await cacheHelper.exist(f.mediaUrl));
          if (cacheExists) {
            item = f;
            throw 'found';
          }
        });
      } catch (e) {
        //if (e !== 'found') throw e;
      }
    }
    if (!item) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    await storage.updateInstagramViewCount(item.id, categoryId === 57 ? "IMAGE" : "VIDEO");
    dispatch("prepareContent", item);
  },
  prepareContent({ dispatch, commit }, content) {
    commit("setState", {
      description: content.description,
      likeCount: content.likeCount + 490,
      commentsCount: content.commentsCount + 47,
      userName: content.userName,
      profileImageUrl: process.env.VUE_APP_BASE_CDN_URL + "/" + content.profileImageUrl,
      mediaDate: content.mediaDate,
      mediaType: content.mediaType
    });
    if (content.mediaType === "IMAGE") {
      commit(
        "backgroundImage/setState",
        {
          fileName: process.env.VUE_APP_BASE_CDN_URL + "/" + content.mediaUrl,
          description: "",
          displaySecond: 12
        },
        { root: true }
      );
    } else if (content.mediaType === "VIDEO") {
      commit(
        "backgroundVideo/setState",
        {
          fileName: process.env.VUE_APP_BASE_CDN_URL + "/" + content.mediaUrl,
          fileType: "video/mp4",
          description: "",
          videoSound: false
        },
        { root: true }
      );
    } else {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    dispatch("play", "Instagram", { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};