import { http } from "./http-base";
import router from "../router.js";
import jwtHelper from "./jwt-helper";
import userManager from "../manager/modules/user-manager";

function get(url, body) {
  try {
    return http
      .get(url, body)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        errorMessage(e);
        return null;
      })
      .finally(() => { });

  } catch (error) {
    window.console.log(error)
    return null;
  }
}
function getXml(url) {
  try {
    return http
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        errorMessage(e);
        return null;
      })
      .finally(() => { });
  } catch (error) {
    window.console.log(error)
    return null;
  }
}
function post(url, body) {
  try {
    return http
      .post(url, body)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        var err = e.response && e.response.data ? e.response.data : e.reposne || e;
        errorMessage(err);
        return err;

      })
      .finally(() => { });
  } catch (error) {
    window.console.log(error)
    return null;
  }
}

async function errorMessage(message) {
  try {
    if (message && (message.StatusCode && message.StatusCode == 401) || (message.toString().indexOf('401') !== -1)) {
      if (localStorage.getItem("token")) {
        var time = localStorage.getItem('expiration');
        if (time) {
          if (new Date(time) < new Date()) {
            window.console.log("token has expired");
            userManager.logOut();
          }
        }
        await jwtHelper.refreshToken();
      } else {
        window.console.log("errorMessage_catch");
        userManager.logOut();
      }
    }
    if (message && message.StatusCode && message.StatusCode == 403) {
      router.push({ path: "/" });
    }
    //toast.error(message.Message ? JSON.stringify(message.Message) : JSON.stringify(message));    
  } catch (error) {
    window.console.log(error)
  }
}

export default { get, post, getXml };
