import { getDb as db } from "../index";

export async function addDailyHoroscope(data) {
    await db().dailyHoroscopes.put(data);
}

export async function clearDailyHoroscopes() {
    await db().dailyHoroscopes.clear();
}

export async function getDailyHoroscopes() {
    return await db().dailyHoroscopes.orderBy('viewCount').toArray();
}

export async function getFirstDailyHoroscopeOrderByViewCount() {
    let results = db().dailyHoroscopes.orderBy('viewCount');
    return await results.first();
}

export async function updateDailyHoroscopeViewCount(id, viewCount) {
    await db().dailyHoroscopes.update(id, {
        viewCount: viewCount
    });
}


export default {
    addDailyHoroscope,
    clearDailyHoroscopes,
    getDailyHoroscopes,
    getFirstDailyHoroscopeOrderByViewCount,
    updateDailyHoroscopeViewCount,
}