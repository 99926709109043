import { getDb as db } from "../index";

export async function addAccountClient(data) {
    await db().accountClient.put({
        id: data.id,
        parentAccountId: data.parentAccountId,
        layoutId: data.layoutId,
        layoutFileName: data.layoutFileName,
        logoPosition: data.logoPosition,
        userId: data.userId,
        active: data.active,
        adTagUrl : data.adTagUrl
    });
}

export async function clearAccountClients() {
    await db().accountClient.clear();
}

export async function deleteAccountClientId(id) {
    await db().accountClient.delete(id);
}

export async function getAccountClientById(id) {
    return await db().accountClient.get(id);
}

export async function getAccountClients() {
    return await db().accountClient.orderBy('id').toArray();
}

export async function getAccountClientAdTagUrl() {
    var acc = await db().accountClient.orderBy('id').first();
    if (acc) {
        return acc.adTagUrl;
    }
    return null;
}

export default {
    addAccountClient,
    clearAccountClients,
    deleteAccountClientId,
    getAccountClientById,
    getAccountClients,
    getAccountClientAdTagUrl
}