import axios from "axios";

export const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL + "/api/"
})

http.CancelToken = axios.CancelToken
http.isCancel = axios.isCancel

http.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token')
        if (token && config.url && config.url.indexOf('adx') === -1) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)