const state = {
    fileName: "",
    description: "",
    title: "",
    displaySecond: 10,
    properties: [],
    categoryId: 0
}

const mutations = {
    setState(state, data) {
        state.fileName = data.fileName;
        state.title = data.title;
        state.displaySecond = data.displaySecond;
        state.description = data.description;
        state.properties = data.properties;
        state.categoryId = data.categoryId;
    },
    setFileName(state, fileName) {
        state.fileName = fileName;
    },
    setDisplaySecond(state, displaySecond) {
        state.displaySecond = displaySecond;
    },
    setDescription(state, description) {
        state.description = description;
    },
    setTitle(state, title) {
        state.title = title;
    },
}

export default {
    namespaced: true,
    state,
    mutations
}