const state = {
    list: [],
    timerActive: false
};

const mutations = {
    addToList(state, product) {
        product.fileName = product.productFile ? process.env.VUE_APP_BASE_CDN_URL + '/' + product.productFile.fileName : '/images/bg/start.jpg';
        state.list.push(product);
    },
    removeFromList(state, id) {
        var index = state.list.findIndex(x => x.id === id);
        if (index >= 0)
            state.list.splice(index, 1);
    },
    setTimerStatus(state, status) {
        state.timerActive = status;
    },
    decreaseTime(state, id) {
        state.list.find(x => x.id === id).displaySecond--;
    }
};

const actions = {
    startTimer(context) {
        if (!context.state.timerActive) {
            context.commit("setTimerStatus", true);
            let timer = setInterval(() => {
                if (!context.state.timerActive) clearInterval(timer);
                else context.dispatch("checkTime");
            }, 1000);
        }
    },
    checkTime({ dispatch, commit, state }) {
        state.list.forEach(item => {
            commit("decreaseTime", item.id);
            if (item.displaySecond < 0) {
                commit("removeFromList", item.id);
            }
        });
        if (!state.list || !state.list.length) {
            commit("setTimerStatus", false);
            dispatch("setNextPlaylist", null, { root: true });
        }
    },
    async addToList({ dispatch, commit }, product) {
        await commit("removeFromList", product.id);
        await commit("addToList", product);
        dispatch("play", "Product", { root: true });
        dispatch("startTimer");
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
