import storage from "../../storage/index";
import cacheHelper from "../../shared/cache-helper";
import router from "../../router";
import hub from "../../hub";

export async function logOut() {
  return storage.deleteDb().then(() => {
    return cacheHelper.clear().then(() => {
      return hub.disconnectFromHub().then(() => {
        window.console.log("Loging Out");
        localStorage.removeItem("token");
        localStorage.removeItem("expiration");
        router.push({ path: "/login" });
      });
    });
  });
}

export default{
    logOut
}