import http from "../../shared/http";
import fixtureStorage from '../../storage/modules/fixture';
import { checkPlaylistByCategoryName } from './playlist-manager';
import store from "../../store/index";

export async function downloadFixture() {
  if (!(await checkPlaylistByCategoryName("Fikstür"))) {
    await fixtureStorage.clearFixtures();
    return;
  }
  let fixtures = await http.get("fixtures/getlistbyleagueid?leagueId=1");
  if (fixtures) {
    await fixtureStorage.clearFixtures();
    await fixtureStorage.bulkAddFixture(fixtures);
    await store.dispatch("fixture/loadFixture");
  }
}

export default {
  downloadFixture
}