import { getDb as db } from "../index";

export function addFooterHeaderContent(data) {
    return db().footerHeaderContents.put({
        id: data.id,
        title: data.title,
        contentType: data.contentType
    });
}

export async function bulkAddFooterHeaderContent(list) {
    var items = list.map(data => ({
        id: data.id,
        title: data.title,
        contentType: data.contentType
    }));
    await db().footerHeaderContents.bulkAdd(items);
}

export async function clearFooterHeaderContents() {
    await db().footerHeaderContents.clear();
}

export async function deleteFooterHeaderContentById(id) {
    await db().footerHeaderContents.delete(id);
}

export function getFooterHeaderContentById(id) {
    return db().footerHeaderContents.get(id);
}

export function getFooterHeaderContentsByType(contentType) {
    return db().footerHeaderContents.where("contentType").equals(contentType).toArray()
}

export function getFooterHeaderContents() {
    return db().footerHeaderContents.orderBy('id').toArray();
}

export default{
    addFooterHeaderContent,
    clearFooterHeaderContents,
    deleteFooterHeaderContentById,
    getFooterHeaderContentById,
    getFooterHeaderContentsByType,
    getFooterHeaderContents,
    bulkAddFooterHeaderContent
}