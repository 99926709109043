import { getDb as db } from "../index";

export async function addInstagramContent(data) {
    await db().instagramContents.put(data);
}

export async function clearInstagramContents() {
    await db().instagramContents.clear();
}

export async function getInstagramVideos() {
    let results = db().instagramContents.orderBy('viewCount').filter(function (c) {
        return c.mediaType === "VIDEO";
    });
    return await results.toArray();
}

export async function getInstagramImages() {
    let results = db().instagramContents.orderBy('viewCount').filter(function (c) {
        return c.mediaType === "IMAGE";
    });
    return await results.toArray();
}



export async function getInstagramContents() {
    return await db().instagramContents.orderBy('viewCount').toArray();
}

export async function getFirstInstagramImageContentOrderByViewCount() {
    let results = db().instagramContents.orderBy('viewCount').filter(function (c) {
        return c.mediaType === "IMAGE";
    });
    return await results.first();
}

export async function getFirstInstagramVideoContentOrderByViewCount() {
    let results = db().instagramContents.orderBy('viewCount').filter(function (c) {
        return c.mediaType === "VIDEO";
    });
    return await results.first();
}

// export function updateInstagramViewCount(instagramContentId, viewCount) {

//     return db().instagramContents.update(instagramContentId, {
//         viewCount: viewCount
//     });
// }


export async function updateInstagramViewCount(instagramContentId, mediaType) {
    let results = db().instagramContents.orderBy('viewCount').filter(function (c) {
        return c.mediaType === mediaType;
    });
    var maxItem = await results.last();
    await db().instagramContents.update(instagramContentId, {
        viewCount: maxItem.viewCount + 1
    });
}

export default {
    addInstagramContent,
    clearInstagramContents,
    getInstagramContents,
    getFirstInstagramImageContentOrderByViewCount,
    getFirstInstagramVideoContentOrderByViewCount,
    updateInstagramViewCount,
    getInstagramVideos,
    getInstagramImages
}