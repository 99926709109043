import { getDb as db } from "../index";


export function addScoreBoard(data) {
    return db().scoreboards.put({ ...data });
}

export async function bulkAddScoreBoard(list) {
    await db().scoreboards.bulkAdd(list);
}

export async function clearScoreboards() {
    await db().scoreboards.clear();
}

export async function getScoreboardByLeagueType(leagueType) {
    let results = db().scoreboards.orderBy('orderNo').filter((c) => c.leagueType === leagueType);
    return await results.toArray();
}


export default {
    addScoreBoard,
    clearScoreboards,
    getScoreboardByLeagueType,
    bulkAddScoreBoard
}