
import { deleteByKey, addFileToCache, exist } from "../../shared/cache-helper";

export async function downloadTrafficMap(cityId) {
    if (!cityId || cityId === 0) return;
    let key = `${process.env.VUE_APP_BASE_CDN_URL}/Resources/Maps/${cityId}.jpg`;
    let mapExists = await exist(key);
    if (mapExists) {
        await deleteByKey(key);
    }
    await addFileToCache(key);
}

export default {
    downloadTrafficMap
}