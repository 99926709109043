
import http from "../../shared/http";
import accountStorage from '../../storage/modules/account';
import store from "../../store/index";
import { addFileToCache } from '../../shared/cache-helper';
import jwtHelper from "../../shared/jwt-helper";
import trafficMapManager from './traffic-map-manager';
import weatherManager from './weather-manager';
import prayerTimesManager from './prayer-times-manager';
import pharmacyManager from './pharmacy-manager';
import { checkPlaylistByCategoryName } from './playlist-manager';

export async function downloadAccount() {
  let account = await http.get(`accounts/getviewbyid?accountId=${jwtHelper.getAccountId()}`);
  if (account) {
    await accountStorage.clearAccounts();
    await accountStorage.addAccount(account);
    let logoFileName = account.parentLogoFileName || account.logoFileName;
    if (logoFileName)
      await addFileToCache(
        process.env.VUE_APP_BASE_CDN_URL + "/" + logoFileName
      );

    await store.dispatch("loadAccount");
    if (account.cityId) {
      if (await checkPlaylistByCategoryName("Hava Durumu"))
        await weatherManager.downloadWeatherForecasts(account.cityId);
      if (await checkPlaylistByCategoryName("Namaz Saatleri"))
        await prayerTimesManager.downloadPrayerTimes(account.cityId);
      if (await checkPlaylistByCategoryName("Trafik Haritası"))
        await trafficMapManager.downloadTrafficMap(account.cityId);
    }
    if (account.districtId && (await checkPlaylistByCategoryName("Nöbetçi Eczaneler"))) {
      await pharmacyManager.downloadPharmacies(account.districtId);
    }
  }

}

export default {
  downloadAccount
}