import http from "../../shared/http";
import advertisementStorage from '../../storage/modules/advertisement';
import { addFileToCache } from "../../shared/cache-helper";
import { checkPlaylistByCategoryName } from './playlist-manager'
import accountClientStorage from '../../storage/modules/account-client';
function getByAttr(elements, attrib, val) {
    for (var i = 0; i < elements.length; i++) {
        var currentElement = elements[i];
        var currentAttributes = currentElement.attributes;
        for (var x = 0; x < currentAttributes.length; x++) {
            if (currentAttributes[x].name === attrib
                && currentAttributes[x].value === val
            ) {
                return currentElement.textContent;
            }
        }
    }
}
function checkAssetIsSupported(sourcePath) {
    var assetExt = sourcePath.split(".").pop();
    var s = assetExt;
    var n = s.indexOf("?");
    s = s.substring(0, n != -1 ? n : s.length);
    switch (s) {
        case "mp4":
            return "video";
        case "MP4":
            return "video";
        case "mov":
            return "video";
        case "MOV":
            return "video";
        case "webm":
            return "video";
        case "WEBM":
            return "video";
        default:
            return null;
    }
}
export async function callEvent(url) {
    try {
        return http.get(url);
    } catch (error) {
        window.console.log(error);
        return;
    }
}

var reload = 0;
export async function downloadNextAdvertisement() {
    if (!(await checkPlaylistByCategoryName("Advertisement"))) {
        window.console.log('no reklam');
        return;
    }
    var parser = new DOMParser();
    var tagUrl = await accountClientStorage.getAccountClientAdTagUrl();//"https://adx.adform.net/adx/?mid=1006257&t=2";//
    if (!tagUrl) {
        window.console.log('no ad tag url');
        return;
    }
    return http.getXml(tagUrl).then(async xml => {
        if (xml) {
            var res = parser.parseFromString(xml, "text/xml");
            var vastUrl = res.getElementsByTagName('VASTAdTagURI')[0].textContent;

            if (!vastUrl) {
                if (reload == 0) {
                    reload = 1;
                    window.console.log("RELOAD TAG");
                    return;
                } else if (reload == 1) {
                    window.console.log("VASTAdTagURI UNDEFINED");
                    return;
                }
            }
            // ======================= get video file url and event urls ==================== //
            return http.getXml(vastUrl).then(async res => {
                if (res) {
                    var result = parser.parseFromString(res, "text/xml");
                    var trackings = result.getElementsByTagName('Tracking');
                    var playbackStart = getByAttr(trackings, 'event', 'start');
                    var playback25 = getByAttr(trackings, 'event', 'firstQuartile');
                    var playback50 = getByAttr(trackings, 'event', 'midpoint');
                    var playback75 = getByAttr(trackings, 'event', 'thirdQuartile');
                    var playbackComplete = getByAttr(trackings, 'event', 'complete');
                    var mediaFiles = result.getElementsByTagName('MediaFile');
                    var sourcePath = getByAttr(mediaFiles, 'type', 'video/mp4');
                    if (sourcePath) {
                        var isVideo = checkAssetIsSupported(sourcePath);
                        if (isVideo && isVideo === 'video') {
                            var ad = {
                                id: 1,
                                adTagUrl: vastUrl,
                                mediaFile: sourcePath,
                                startLink: playbackStart,
                                firstQuartileLink: playback25,
                                midpointLink: playback50,
                                thirdQuartileLink: playback75,
                                completeLink: playbackComplete
                            }
                            await advertisementStorage.addAdvertisement(ad);
                            await addFileToCache(sourcePath);
                        }
                    }
                }
            });
        }
    }).catch((err) => {
        window.console.log(err);
    });
}
export async function deleteAdvertisement(fileName) {
    window.console.log("deleteAdvertisement ",fileName);
    await advertisementStorage.deleteAdvertisement();
}

export default {
    downloadNextAdvertisement,
    deleteAdvertisement,
    callEvent
}