import Vue from 'vue'
import Router from 'vue-router'

const Login = () => import('@/views/Login')
const MainPage = () => import('@/views/MainPage')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'Anasayfa',
      component: MainPage,
    },
    {
      path: '/login',
      name: 'Giriş Yap',
      component: Login,
    },
    {
      path: '*',
      redirect: '/',
    },
  ]
})