import stringHelper from './string-helper';
const days = ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"];
function addDay(str, count) {
    var date = new Date(str);
    let newDate = new Date(date.setDate(date.getDate() + count));
    let day = stringHelper.padRight(newDate.getDate(), '00');
    let month = stringHelper.padRight(newDate.getMonth() + 1, '00');
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
}

function addMonth(str, count) {
    var date = new Date(str);
    let newDate = new Date(date.setMonth(date.getMonth() + count));
    let day = stringHelper.padRight(newDate.getDate(), '00');
    let month = stringHelper.padRight(newDate.getMonth() + 1, '00');
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
}

function addYear(str, count) {
    var date = new Date(str);
    let newDate = new Date(date.setFullYear(date.getFullYear() + count));
    let day = stringHelper.padRight(newDate.getDate(), '00');
    let month = stringHelper.padRight(newDate.getMonth() + 1, '00');
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
}

function toInputDateFormat(str) {
    var date = new Date(str);
    let day = stringHelper.padRight(date.getDate(), '00');
    let month = stringHelper.padRight(date.getMonth() + 1, '00');
    let year = date.getFullYear();
    return day + "/" + month + "/" + year;
}

function getDayNameByIndex(dayOfWeek) {
    return days[dayOfWeek];
}

function getCurrenctDateTimeString() {
    var currentdate = new Date();
    var datetime = "" + currentdate.getDate() + "/"
        + (currentdate.getMonth() + 1) + "/"
        + currentdate.getFullYear() + " @ "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();
    return datetime;
}

function getCurrentDateString() {
    var currentdate = new Date();
    var date = "" + currentdate.getDate() + "/"
        + (currentdate.getMonth() + 1) + "/"
        + currentdate.getFullYear();
    return date;
}

function getCurrenctTimeString() {
    return new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
    });
}

export default { addDay, addMonth, addYear, toInputDateFormat, getDayNameByIndex, getCurrenctDateTimeString, getCurrentDateString, getCurrenctTimeString };