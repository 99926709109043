import { getDb as db } from "../index";

export async function addAccount(data) {
    await db().accounts.put({
        id: data.id,
        title: data.title,
        cityId: data.cityId,
        cityName: data.cityName,
        districtId: data.districtId,
        districtName: data.districtName,
        logoFileId: data.logoFileId,
        logoFileName: data.logoFileName,
        parentLogoFileName: data.parentLogoFileName,
        parentAccountId: data.parentAccountId,
        parentAccountTitle: data.parentAccountTitle,
        playlistAccountId: data.playlistAccountId,
        playlistAccountTitle: data.playlistAccountTitle,
        dealerId: data.dealerId,
        dealerName: data.dealerName,
        detail: data.detail,
        lastUpdateDate: data.lastUpdateDate,
        youtubeLink: data.youtubeLink,
        active: data.active,
        expiryDate : data.expiryDate
    });
}

export async function clearAccounts() {
    await db().accounts.clear();
}

export async function deleteAccountById(id) {
    await db().accounts.delete(id);
}

export async function getAccountById(id) {
    return await db().accounts.get(id);
}

export async function getAccounts() {
    return await db().accounts.orderBy('id').toArray();
}

export default {
    addAccount,
    clearAccounts,
    deleteAccountById,
    getAccountById,
    getAccounts
}