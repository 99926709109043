import Vue from "vue";
import Vuex from "vuex";
import accountStorage from '../storage/modules/account';
import accountClientStorage from '../storage/modules/account-client';
import playlistStorage from '../storage/modules/playlist';
import footerBar from "./modules/footer-bar";
import backgroundImage from "./modules/background-image";
import backgroundMultiImage from "./modules/background-multi-image";
import backgroundVideo from "./modules/background-video";
import backgroundText from "./modules/background-text";
import currency from "./modules/currency";
import weather from "./modules/weather";
import headerBar from "./modules/header-bar";
import prayerTime from "./modules/prayer-time";
import pharmacy from "./modules/pharmacy";
import news from "./modules/news";
import fixture from "./modules/fixture";
import scoreboard from "./modules/scoreboard";
import content from "./modules/content";
import instagram from "./modules/instagram";
import advertisement from "./modules/advertisement";
import dailyHoroscope from "./modules/daily-horoscope";
import todayInHistory from "./modules/today-in-history";
import contentManager from "../manager/modules/content-manager";
//import contentHelper from "../shared/content-helper";
import foursquareTip from "./modules/foursquare-tip";
import foursquareCheckin from "./modules/foursquare-checkin";
import product from './modules/product';
import luckyTable from './modules/lucky-table';
import birthday from './modules/birthday';
import welcome from './modules/welcome';
import realty from "./modules/realty";
import dateHelper from "../shared/date-helper";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    standartLayout: "layout11",
    mainTemplate: "",
    logo: "",
    logoPosition: "bottom-right",
    cityName: "",
    districtId: 0,
    playlists: [],
    currentPlaylistIndex: -1,
    cityId: 0,
    youtubeLink: "",
    youtubePlaylistId: "",
    isAccountActive: true
  },
  mutations: {
    setStandartLayout(state, layoutFileName) {
      state.standartLayout = layoutFileName;
    },
    setLogo(state, logo) {
      state.logo = logo;
    },
    setLogoPosition(state, logoPosition) {
      state.logoPosition = logoPosition;
    },
    setCityName(state, cityName) {
      state.cityName = cityName;
    },
    setCityId(state, cityId) {
      state.cityId = cityId;
    },
    setDistrictId(state, districtId) {
      state.districtId = districtId;
    },
    setPlaylists(state, playlists) {
      state.playlists = playlists;
    },
    setCurrentPlaylistIndex(state, playlistIndex) {
      state.currentPlaylistIndex = Number(playlistIndex);
      localStorage.setItem("currentPlaylistIndex", state.currentPlaylistIndex);
    },
    setMainTemplate(state, mainTemplate) {
      state.mainTemplate = mainTemplate;
    },
    setYoutubeLink(state, youtubeLink) {
      state.youtubeLink = youtubeLink;
    },
    setAccountActiveness(state, active) {
      state.isAccountActive = active;
    },
  },
  actions: {
    async loadStore(context) {
      await context.dispatch("loadPlaylists");
      await context.dispatch("loadAccountClient");
      await context.dispatch("loadAccount");
      await context.dispatch("currency/loadCurrencies");
      await context.dispatch("weather/loadWeatherForecasts");
      await context.dispatch("prayerTime/startTimer");
      await context.dispatch("footerBar/loadContents");
      await context.dispatch("headerBar/loadContents");
      await context.dispatch("pharmacy/loadPharmacies");
      await context.dispatch("fixture/loadFixture");
      await context.dispatch("scoreboard/loadScoreboard");
    },
    async loadAccount(context) {
      var list = await accountStorage.getAccounts()
      if (list && list[0]) {
        context.commit(
          "setLogo",
          list[0].parentLogoFileName || list[0].logoFileName
        );
        context.commit("setCityName", list[0].cityName);
        context.commit("setDistrictId", list[0].districtId);
        context.commit("setCityId", list[0].cityId);
        context.commit("setYoutubeLink", list[0].youtubeLink);
        if (list[0].active === true || list[0].active === false)
          context.commit("setAccountActiveness", list[0].active);
        if (list[0].expiryDate && new Date(list[0].expiryDate) < new Date(dateHelper.addDay(new Date(), -1)))
          context.commit("setAccountActiveness", false);
        if (list[0].youtubeLink) {
          await context.dispatch("setNextPlaylist");
        }
      }
    },
    loadAccountClient(context) {
      return accountClientStorage.getAccountClients().then(list => {
        if (list && list[0]) {
          context.commit("setStandartLayout", list[0].layoutFileName);
          context.commit("setLogoPosition", list[0].logoPosition);
        }
      });
    },
    async loadPlaylists(context) {
      var list = await playlistStorage.getPlaylists();
      if (list) {
        context.commit(
          "setPlaylists",
          list.map(u => u.data)
        );
        let pIndex = localStorage.getItem("currentPlaylistIndex") || -1;
        context.commit("setCurrentPlaylistIndex", pIndex);
        context.dispatch("setNextPlaylist");
      }
    },
    async setNextPlaylist({ dispatch, commit, state }) {
      window.console.clear();
      await contentManager.checkAndDeactivateOneTimeContent();
      if (state.youtubeLink && navigator.onLine) {
        if (state.mainTemplate !== "Youtube") dispatch("play", "Youtube");
        setTimeout(() => dispatch("setNextPlaylist"), 2000);
        return;
      }
      if (!state.playlists || !state.playlists.length) {
        window.console.log('playlist is empty!!')
        return;
      }
      commit("setCurrentPlaylistIndex", state.currentPlaylistIndex + 1);
      if (state.currentPlaylistIndex >= state.playlists.length) {
        commit("setCurrentPlaylistIndex", -1);
        dispatch("setNextPlaylist");
        return;
      }
      let item = state.playlists[state.currentPlaylistIndex];
      if (!item) {
        commit("setCurrentPlaylistIndex", -1);
        dispatch("setNextPlaylist");
        return;
      }
      if (item.needInternet && !navigator.onLine) {
        dispatch("setNextPlaylist");
        return;
      }
      dispatch("selectModule", item);
    },
    async selectModule({ dispatch }, item) {
      if (item.categoryTypeName === "Content") {
        dispatch("content/setContent", item.categoryId);
      } else if (item.categoryTypeName === "FixedContent") {
        dispatch("play", item.categoryFileName);
      } else if (item.categoryTypeName === "News") {
        dispatch("news/setNewsByCategoryId", item.categoryId);
      } else if (item.categoryTypeName === "DailyHoroscope") {
        dispatch("dailyHoroscope/setContent");
      } else if (item.categoryTypeName === "TodayInHistory") {
        dispatch("todayInHistory/setContent");
      } else if (item.categoryTypeName === "Instagram") {
        dispatch("instagram/setContent", item.categoryId);
      } else if (item.categoryTypeName === "Advertisement") {
        dispatch("advertisement/setContent");
      } else if (item.categoryTypeName === "FoursquareTip") {
        dispatch("foursquareTip/setContent");
      } else {
        dispatch("setNextPlaylist");
      }
    },
    play({ commit }, categoryFileName) {
      commit("setMainTemplate", "Empty");
      setTimeout(() => commit("setMainTemplate", categoryFileName), 1);
    }
  },
  modules: {
    footerBar,
    headerBar,
    backgroundImage,
    backgroundMultiImage,
    backgroundVideo,
    backgroundText,
    currency,
    weather,
    prayerTime,
    pharmacy,
    news,
    fixture,
    scoreboard,
    instagram,
    dailyHoroscope,
    todayInHistory,
    content,
    foursquareTip,
    foursquareCheckin,
    product,
    luckyTable,
    birthday,
    welcome,
    realty,
    advertisement
  },
  strict: process.env.NODE_ENV !== "production"
});
