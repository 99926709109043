import http from "../../shared/http";
import checkinStorage from '../../storage/modules/checkin';
import { checkPlaylistByCategoryName } from './playlist-manager'
import store from "../../store/index";

export async function downloadFoursquareCheckin() {
    if (!(await checkPlaylistByCategoryName("Foursquare Checkin"))) {
        await checkinStorage.clearCheckins();
        return;
    }
    let checkins = await http.get(`foursquarecheckins/getactivelist`);
    if (checkins && checkins.length) {
        var oldCheckins = await checkinStorage.getCheckins();
        if (oldCheckins && oldCheckins.length)
            checkins = checkins.filter(n => !oldCheckins.some(n2 => n.checkInId === n2.checkInId));
        if (checkins && checkins.length)
            await store.dispatch("foursquareCheckin/addToList", checkins);
    }
}

export default {
    downloadFoursquareCheckin
}