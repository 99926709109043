import jwtDecode from "jwt-decode";
import http from "./http";

function getParsedJwt() {
  var token = localStorage.getItem("token");
  if (!token) return null;
  return jwtDecode(token);
}

function getRoles() {
  var parsedJwt = getParsedJwt();
  if (!parsedJwt) return null;
  return parsedJwt[
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
  ];
}

function getEmail() {
  var parsedJwt = getParsedJwt();
  if (!parsedJwt) return null;
  return parsedJwt["email"];
}

function getNameSurname() {
  var parsedJwt = getParsedJwt();
  if (!parsedJwt) return null;
  return parsedJwt[
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
  ];
}

function getUserId() {
  var parsedJwt = getParsedJwt();
  if (!parsedJwt) return null;
  return Number(
    parsedJwt[
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    ]
  );
}

function getAccountId() {
  var parsedJwt = getParsedJwt();
  if (!parsedJwt) return null;
  return Number(parsedJwt["accountid"]);
}

function getDealerId() {
  var parsedJwt = getParsedJwt();
  if (!parsedJwt) return null;
  return Number(parsedJwt["dealerid"]);
}

function getUserName() {
  var parsedJwt = getParsedJwt();
  if (!parsedJwt) return null;
  return parsedJwt["unique_name"];
}

function isAdmin() {
  let roles = getRoles();
  if (!roles) return false;
  if (roles.includes("Admin")) return true;
  return false;
}

function isClient() {
  let roles = getRoles();
  if (!roles) return false;
  if (roles.includes("Client")) return true;
  return false;
}

export async function refreshToken() {
  try {
    if (!navigator.onLine) return;
    var data = await http.get(`auth/refreshtoken`);

    if (data && data.token) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("expiration", data.expiration);
      window.console.log("RefreshToken_Success");
    } else {
      window.console.log("Token not found (RefreshToken)");
    }
  } catch (error) {
    window.console.log(error);
  }


}

export default {
  getParsedJwt,
  getRoles,
  getEmail,
  getNameSurname,
  getUserId,
  getAccountId,
  getDealerId,
  getUserName,
  isAdmin,
  isClient,
  refreshToken
};
