import { getDb as db} from "../index";

export async function addFourSquareTip(data) {
    await db().fourSquareTips.put(data);
}

export async function clearFourSquareTips() {
    await db().fourSquareTips.clear();
}

export async function getFourSquareTips() {
    return await db().fourSquareTips.orderBy('viewCount').toArray();
}

export async function getFirstFourSquareTipOrderByViewCount() {
    return await db().fourSquareTips.orderBy('viewCount').first();
}

export async function updateFourSquareTipViewCount(fourSquareTipId) {
    let results = db().fourSquareTips.orderBy('viewCount');
    var maxItem = await results.last();
    await db().fourSquareTips.update(fourSquareTipId, {
        viewCount: maxItem.viewCount + 1
    });
}

export default {
    addFourSquareTip,
    clearFourSquareTips,
    getFourSquareTips,
    getFirstFourSquareTipOrderByViewCount,
    updateFourSquareTipViewCount
}