const state = {
    fileName: "",
    fileType: "video/mp4",
    description: "",
    videoSound: false,
    isReklam : false,
    id: null
}

const mutations = {
    setState(state, data) {
        state.id = data.id;
        state.fileName = data.fileName;
        state.fileType = data.fileType;
        state.description = data.description;
        state.videoSound = data.videoSound;
        state.isReklam = data.isReklam;
    },
    setFileName(state, fileName) {
        state.fileName = fileName;
    },
    setFileType(state, fileType) {
        state.fileType = fileType;
    },
    setDescription(state, description) {
        state.description = description;
    },
    setVideoSound(state, videoSound) {
        state.videoSound = videoSound;
    },
}

export default {
    namespaced: true,
    state,
    mutations
}