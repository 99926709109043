import Dexie from "dexie";

var db = new Dexie("istMedyaDb");

db.version(3).stores({
    contents: "id,categoryId,viewCount,data",
    playlists: "id,categoryId,orderNumber,data",
    //categories: "id,categoryTypeId,typeName,categoryName,fileName,isVideo,isImage,isMultiContent,needInternet",
    accountClient: "id,adTagUrl,parentAccountId,layoutId,layoutFileName,logoPosition,userId,active",
    currencies: "id,name,symbol,shortKey,currencyValue,status,lastUpdateDate",
    accounts: "id,title,cityId,cityName,districtId,districtName,logoFileId,logoFileName,parentLogoFileName,parentAccountId,parentAccountTitle,playlistAccountId,playlistAccountTitle,dealerId,dealerName,detail,youtubeLink,active,expiryDate",
    weatherForecasts: "id,cityId,cityName,districtId,districtName,degrees,forecast,forecastKey,lastUpdateDate",
    footerHeaderContents: "id,title,contentType",
    prayerTimes: "id,cityId,date,dawnTime,sunTime,noonPrayer,afternoonPrayer,eveningPrayer,nightPrayer,lastUpdateDate",
    pharmacies: "id,cityId,districtId,pharmacyName,address,phone,lastUpdateDate",
    news: "id,viewCount,guid,title,description,imageUrl,fileName,publishDate,categoryName",
    scoreboards: "id,leagueType,orderNo,teamName,playedMatchCount,winCount,drawCount,lossCount,average,score,createDate",
    fixtures: "id,leagueType,hostTeam,awayTeam,score,matchDate,matchTime,createDate",
    instagramContents: "id,viewCount,accountId,description,likeCount,mediaType,commentsCount,mediaDate,permaLink,userName,businessAccountId,profileImageUrl,facebookPageId,mediaId",
    dailyHoroscopes: "id,viewCount,name,comment,createDate",
    todayInHistories: "id,viewCount,description,eventDate,createDate",
    fourSquareTips: "id,viewCount,accountId,tipId,userId,fullName,comment,profileImage,image,likeCount,tipDate,createDate",
    fourSquareCheckins: "id,checkInId,createDate",
    advertisements: "id,adTagUrl,mediaFile,startLink,firstQuartileLink,midpointLink,thirdQuartileLink,completeLink",
});
db.on("ready", function () {
});

export function deleteDb() {
    return db.delete();
}

export function getDb()
{
    return db;
}

export default {
    deleteDb,
    getDb
}