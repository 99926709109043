import storage from "../../storage/modules/content";
import cacheHelper from "../../shared/cache-helper";
import contentHelper from "../../shared/content-helper";
import utility from "../../shared/utils";

const state = {};

const mutations = {};

const actions = {
  async setContent({ dispatch }, categoryId) {
    let content = null;
    let list = await storage.getContentByCategoryId(categoryId);
    if (!list || list.length == 0) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }

    list = list.sort((a, b) => (a.viewCount < b.viewCount ? -1 : 1)); // sort ascending by view count

    try {
      await utility.asyncForEach(list, async itm => {
        let f = itm.data;
        if (f) {
          let contentHasTime = contentHelper.checkContentTime(f); // check for time

          if (contentHasTime) {
            if (f.files && f.files.length) { // if it has file check cache
              f.files[0].fileName = utility.replaceSlashAndBackSlashes(f.files[0].fileName);
              let cacheExists = await cacheHelper.exist(f.files[0].fileName);
              if (cacheExists) {
                content = f;
                throw 'found';
              }
            } else { // content does not have file(probably text)
              content = f;
              throw 'found';
            }
          }
        }
      });

    } catch (e) {
      //if (e !== 'found') throw e;
      window.console.log(e);
    }

    if (!content) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    await storage.updateContentViewCount(content.id, categoryId);
    dispatch("prepareContent", content);
  },
  prepareContent({ dispatch, commit }, content) {
    var backgroundImageArray = ['BackgroundImage', 'KeyPoint', 'Gunnies', 'Space', 'LegendArtist', 'Fact'];
    if (
      backgroundImageArray.includes(content.categoryFileName) &&
      content.files.length
    ) {
      commit(
        "backgroundImage/setState",
        {
          fileName: `${process.env.VUE_APP_BASE_CDN_URL}/${content.files[0].fileName}`,
          description: content.description,
          title: content.title,
          displaySecond: content.schedule.displaySecond,
          properties: content.properties,
          categoryId : content.categoryId
        },
        { root: true }
      );
    } else if (
      content.categoryFileName === "BackgroundVideo" &&
      content.files.length
    ) {
      commit(
        "backgroundVideo/setState",
        {
          fileName: `${process.env.VUE_APP_BASE_CDN_URL}/${content.files[0].fileName}`,
          fileType: content.files[0].fileType,
          description: content.description,
          videoSound: content.files[0].videoSound,
          isReklam: content.categoryName === 'Reklamlar',
          id: content.id
        },
        { root: true }
      );
    } else if (
      content.categoryFileName === "BackgroundMultiImage" &&
      content.files.length
    ) {
      commit("backgroundMultiImage/setFiles", content.files, { root: true });
    }
    else if (
      content.categoryFileName === "Realty" &&
      content.files &&
      content.files.length &&
      content.properties &&
      content.properties.length
    ) {
      commit("realty/setFiles", content.files, { root: true });
      commit("realty/setProperties", content.properties, { root: true });
    }

    else if (content.categoryFileName === "BackgroundText") {
      commit(
        "backgroundText/setState",
        {
          description: content.description,
          displaySecond: content.schedule.displaySecond
        },
        { root: true }
      );
    } else {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    dispatch("play", content.categoryFileName, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
