import { getDb as db } from "../index";

export async function addCheckin(model) {
    await db().fourSquareCheckins.put({ id: model.id, checkInId: model.checkInId, createDate: new Date() });
}

export async function clearCheckins() {
    await db().fourSquareCheckins.clear();
}

export async function getCheckins() {
    return await db().fourSquareCheckins.orderBy('id').toArray();
}
export async function getCheckinByCheckinId(model) {
    return await db().fourSquareCheckins.where("checkInId").equals(model.checkInId).first();
}



export default {
    getCheckinByCheckinId,
    addCheckin,
    clearCheckins,
    getCheckins,
}