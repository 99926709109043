import { getDb as db } from "../index";

export async function addTodayInHistory(data) {
    await db().todayInHistories.put(data);
}

export async function clearTodayInHistories() {
    await db().todayInHistories.clear();
}

export async function getTodayInHistories() {
    return await db().todayInHistories.orderBy('viewCount').toArray();
}

export async function getFirstTodayInHistoryOrderByViewCount() {
    let results = db().todayInHistories.orderBy('viewCount');
    return await results.first();
}

export async function updateTodayInHistoryViewCount(id, viewCount) {
    await db().todayInHistories.update(id, {
        viewCount: viewCount
    });
}


export default {
    addTodayInHistory,
    clearTodayInHistories,
    getTodayInHistories,
    getFirstTodayInHistoryOrderByViewCount,
    updateTodayInHistoryViewCount,
}