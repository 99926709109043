import storage from "../../storage/modules/fixture";
import dateHelper from "../../shared/date-helper";
import utility from "../../shared/utils";

const state = {
  list: []
};

const mutations = {
  setList(state, fixtures) {
    state.list = fixtures;
  }
};

const actions = {
  getImageByTeamName(context, teamName) {
    if (teamName === "Ankaragücü") return "ankaragucu.png";
    if (teamName === "Konyaspor") return "konyaspor.png";
    if (teamName === "Alanyaspor") return "alanyaspor.png";
    if (teamName === "Kayserispor") return "kayserispor.png";
    if (teamName === "Başakşehir") return "basaksehir.png";
    if (teamName === "Malatyaspor" || teamName === "ÖZNUR KABLO YENİ MALATYA" || teamName === "Öznur Kablo Yeni Malatya") return "malatyaspor.png";
    if (teamName === "Gaziantep FK") return "gaziantep.png";
    if (teamName === "Fenerbahçe") return "fenerbahce.png";
    if (teamName === "Antalyaspor") return "antalyaspor.png";
    if (teamName === "Göztepe") return "goztepe.png";
    if (teamName === "Trabzonspor") return "trabzonspor.png";
    if (teamName === "Kasımpaşa" || teamName === "Kasımpasa" || teamName === "Kasimpaşa" || teamName === "Kasimpasa") return "kasimpasa.png";
    if (teamName === "Galatasaray") return "galatasaray.png";
    if (teamName === "Denizlispor") return "denizlispor.png";
    if (teamName === "Beşiktaş") return "besiktas.png";
    if (teamName === "Sivasspor") return "sivasspor.png";
    if (teamName === "Çaykur Rizespor" || teamName === "Rizespor") return "caykurrizespor.png";
    if (teamName === "Gençlerbirliği") return "genclerbirligi.png";
    if (teamName === "Hatayspor") return "hatayspor.png";
    if (teamName === "Erzurumspor") return "erzurumspor.png";
    if (teamName === "Karagümrük") return "karagumruk.png";
    if (teamName === "İstanbulspor") return "istanbulspor.png";
    if (teamName === "Adana Demirspor" || teamName === "A.Demirspor") return "adanademirspor.png";
    if (teamName === "Giresunspor") return "giresunspor.png";
    if (teamName === "Ümraniye") return "umraniye.png"; if (teamName === "Bodrumspor") return "bodrumspor.png"; if (teamName === "Eyüpspor") return "eyupspor.png"; 
    if (teamName === "Pendikspor") return "Pendikspor.png";    
    if (teamName === "Samsunspor") return "Samsunspor.png";
    return "";
  },
  async loadFixture({ dispatch, commit }) {
    let fixtures = await storage.getFixtureByLeagueType(1);
    if (!fixtures || !fixtures.length) return;
    await utility.asyncForEach(fixtures, async f => {
      f.matchTime = f.matchTime.substring(0, 5);
      f.matchDate = dateHelper.toInputDateFormat(f.matchDate);
      f.hostTeamImage = await dispatch("getImageByTeamName", f.hostTeam);
      f.awayTeamImage = await dispatch("getImageByTeamName", f.awayTeam);
    });
    commit("setList", fixtures);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
