import { getDb as db } from "../index";


export function addFixture(data) {
    return db().fixtures.put({ ...data });
}

export async function bulkAddFixture(data) {
    await db().fixtures.bulkAdd(data);
}

export async function clearFixtures() {
    await db().fixtures.clear();
}

export async function getFixtureByLeagueType(leagueType) {
    return await db().fixtures.where('leagueType').equals(leagueType).toArray()
}


export default {
    addFixture,
    clearFixtures,
    getFixtureByLeagueType,
    bulkAddFixture
}