import html2canvas from 'html2canvas';
import http from '../shared/http';
export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};
export function reloadTwice() {
  window.location.reload(true);
}
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }

}
function takeSnapShot() {
  if (navigator.onLine) {
    let videos = document.querySelectorAll("video");
    if (videos && videos.length) {
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      let w, h;
      const v = videos[0];
      if (!v.src) return; // no video here
      try {
        w = 250;
        h = 150;
        canvas.width = w;
        canvas.height = h;
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(v, 0, 0, w, h);
        const a = canvas.toDataURL();
        ctx.clearRect(0, 0, w, h); // clean the canvas
        var clientId = localStorage.getItem('lastUserId');
        if (clientId && a)
          http.post('accountClients/uploadscreenshot', {
            "id": parseInt(clientId),
            "image": a
          })
      } catch (e) {
        window.console.log(e);
        return;
      }
    }
    else {
      let region = document.querySelector("html");
      html2canvas(region, {
        scale: 0.2,
        useCORS: true,
      }).then((canvas) => {
        let pngUrl = canvas.toDataURL();
        var clientId = localStorage.getItem('lastUserId');
        if (clientId && pngUrl)
          http.post('accountClients/uploadscreenshot', {
            "id": parseInt(clientId),
            "image": pngUrl
          })
      });
    }
  }
}

function checkVersion() {
  try {
    if (navigator.onLine) {
      http.get('accountClients/getlastversion')
        .then(res => {
          if (res) {
            let localVersion = localStorage.getItem('localVersion');
            if (!localVersion || localVersion != res) {
              localStorage.setItem('localVersion', res);
              reloadTwice();
            }
          }
        }).catch(err => {
          window.console.log(err);
        })
    }
  } catch (error) {
    window.console.log(error);
  }
}

function replaceSlashAndBackSlashes(str) {
  if (!str || !str.length) {
    return "";
  }
  str = str.replace(/\\/g, "/");
  str = str.replaceAll("//", "/");
  return str;
}

export default {
  random,
  shuffleArray,
  asyncForEach,
  takeSnapShot,
  reloadTwice,
  replaceSlashAndBackSlashes,
  checkVersion
};
