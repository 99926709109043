const state = {
    files: []
}

const mutations = {
    setFiles(state, files) {
        state.files = files && files.length ? files.sort((a, b) => (a.line > b.line) ? 1 : -1) : [];
    },
}

export default {
    namespaced: true,
    state,
    mutations
}