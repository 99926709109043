import storage from "../../storage/modules/footer-header-cotent";
const state = {
  headerTexts: [""]
};

const getters = {
  headerTextAsString: state => {
    let text = "";
    if (state.headerTexts) state.headerTexts.forEach(t => (text += t + " | "));
    return text;
  }
};

const mutations = {
  setHeaderTexts(state, headerTexts) {
    state.headerTexts = headerTexts;
  }
};

const actions = {
  loadContents(context) {
    storage.getFooterHeaderContentsByType(1).then(list => {
      if (list) {
        context.commit(
          "setHeaderTexts",
          list.map(u => u.title)
        );
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
