import storage from '../../storage/modules/currency';

const state = {
  usdValue: '0',
  usdStatus: 'up',
  euroValue: '0',
  euroStatus: 'up',
  goldValue: '0',
  goldStatus: 'up',
};

const mutations = {
  setCurrencies(state, list) {
    let usd = list.find(c => c.shortKey === "USD");
    let euro = list.find(c => c.shortKey === "EUR");
    let gold = list.find(c => c.shortKey === "GA");

    state.usdValue = usd ? usd.currencyValue.toFixed(2) : 0;
    state.euroValue = euro ? euro.currencyValue.toFixed(2) : 0;
    state.goldValue = gold ? gold.currencyValue.toFixed(1) : 0;

    state.usdStatus = usd && usd.status ? usd.status : "up";
    state.euroStatus = euro && usd.euro ? euro.status : "up";
    state.goldStatus = gold && usd.gold ? gold.status : "up";
  },

};

const actions = {
  loadCurrencies(context) {
    storage.getCurrencies().then(list => {
      if (list) {
        context.commit('setCurrencies', list);
      }
    })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};