import storage from "../../storage/modules/advertisement";
import cacheHelper from "../../shared/cache-helper";

const state = {
  mediaFile: "",
  adTagUrl: "",
  startLink: "",
  firstQuartileLink: "",
  midpointLink: "",
  thirdQuartileLink: "",
  completeLink: ""
};

const mutations = {
  setState(state, data) {
    state.mediaFile = data.mediaFile;
    state.adTagUrl = data.adTagUrl;
    state.startLink = data.startLink;
    state.firstQuartileLink = data.firstQuartileLink;
    state.midpointLink = data.midpointLink;
    state.thirdQuartileLink = data.thirdQuartileLink;
    state.completeLink = data.completeLink;
  }
};

const actions = {
  async setContent({ dispatch }) {
    let item = await storage.getFirstAdvertisementOrderById();
    if (!item) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }

    //await storage.updateAdvertisementViewCount(item.id, item.viewCount + 1);
    let cacheExists =
      item.mediaFile &&
      await cacheHelper.exist(item.mediaFile);

    if (!cacheExists) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    dispatch("prepareContent", item);
  },
  prepareContent({ dispatch, commit }, data) {
    commit("setState", {
      mediaFile: data.mediaFile,
      adTagUrl: data.adTagUrl,
      startLink: data.startLink,
      firstQuartileLink: data.firstQuartileLink,
      midpointLink: data.midpointLink,
      thirdQuartileLink: data.thirdQuartileLink,
      completeLink: data.completeLink,
    });

    commit(
      "backgroundVideo/setState",
      {
        fileName: data.mediaFile,
        fileType: "video/mp4",
        description: "",
        videoSound: false
      },
      { root: true }
    );

    dispatch("play", "Advertisement", { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
