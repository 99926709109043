import storage from "../../storage/modules/prayer-time";
import store from "../index";
import dateHelper from "../../shared/date-helper";
const state = {
  name: "",
  time: "",
  timerActive: false
};

const mutations = {
  setName(state, name) {
    state.name = name;
  },
  setTime(state, time) {
    state.time = time;
  },
  setTimerStatus(state, status) {
    state.timerActive = status;
  }
};

const actions = {
  startTimer(context) {
    if (!context.timerActive) {
      context.commit("setTimerStatus", true);
      setInterval(() => context.dispatch("checkPrayerTime"), 30000);
    }
  },
  checkPrayerTime(context) {
    if (store.state.mainTemplate === "PrayerTime") return;
    let prayerTimeCategoryId = 40;
    if (!store.state.playlists.find(u => u.categoryId === prayerTimeCategoryId))
      return;

    storage.getPrayerTimes().then(list => {
      list = list
        ? list.filter(
            u =>
              new Date(u.date).toLocaleDateString() ===
              new Date().toLocaleDateString()
          )
        : null;
      if (list) {
        let dawnTime = list.find(
          u => dateHelper.getCurrenctTimeString() === u.dawnTime.substring(0, 5)
        );
        let sunTime = list.find(
          u => dateHelper.getCurrenctTimeString() === u.sunTime.substring(0, 5)
        );
        let noonPrayer = list.find(
          u =>
            dateHelper.getCurrenctTimeString() === u.noonPrayer.substring(0, 5)
        );
        let afternoonPrayer = list.find(
          u =>
            dateHelper.getCurrenctTimeString() ===
            u.afternoonPrayer.substring(0, 5)
        );
        let eveningPrayer = list.find(
          u =>
            dateHelper.getCurrenctTimeString() ===
            u.eveningPrayer.substring(0, 5)
        );
        let nightPrayer = list.find(
          u =>
            dateHelper.getCurrenctTimeString() === u.nightPrayer.substring(0, 5)
        );

        if (dawnTime) {
          context.commit("setName", "Sabah");
          context.commit("setTime", dawnTime.dawnTime.substring(0, 5));
        } else if (noonPrayer) {
          context.commit("setName", "Öğle");
          context.commit("setTime", noonPrayer.noonPrayer.substring(0, 5));
        } else if (afternoonPrayer) {
          context.commit("setName", "İkindi");
          context.commit(
            "setTime",
            afternoonPrayer.afternoonPrayer.substring(0, 5)
          );
        } else if (eveningPrayer) {
          context.commit("setName", "Akşam");
          context.commit(
            "setTime",
            eveningPrayer.eveningPrayer.substring(0, 5)
          );
        } else if (nightPrayer) {
          context.commit("setName", "Yatsı");
          context.commit("setTime", nightPrayer.nightPrayer.substring(0, 5));
        }

        if (
          dawnTime ||
          sunTime ||
          noonPrayer ||
          afternoonPrayer ||
          eveningPrayer ||
          nightPrayer
        ) {
          store.commit("setMainTemplate", "PrayerTime");
        }
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
