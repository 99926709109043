import { getDb as db } from "../index";

export function addCurrency(data) {
    return db().currencies.put({
        id: data.id,
        name: data.name,
        symbol: data.symbol,
        shortKey: data.shortKey,
        currencyValue: data.currencyValue,
        status: data.status,
        lastUpdateDate: data.lastUpdateDate
    });
}

export async function bulkAddCurrencies(list) {
    var items = list.map(data => ({
        id: data.id,
        name: data.name,
        symbol: data.symbol,
        shortKey: data.shortKey,
        currencyValue: data.currencyValue,
        status: data.status,
        lastUpdateDate: data.lastUpdateDate
    }));
    await db().currencies.bulkAdd(items);
}

export async function clearCurrencies() {
    await db().currencies.clear();
}

export function deleteCurrencyById(id) {
    return db().currencies.delete(id);
}

export function getCurrencyById(id) {
    return db().currencies.get(id);
}

export function getCurrencies() {
    return db().currencies.orderBy('id').toArray();
}

export default {    
    addCurrency,
    clearCurrencies,
    deleteCurrencyById,
    getCurrencyById,
    getCurrencies,
    bulkAddCurrencies
}