import { getDb as db } from "../index";

export async function addNews(data) {
    await db().news.put({
        id: data.id,
        viewCount: data.viewCount || 0,
        guid: data.guid,
        title: data.title,
        description: data.description,
        imageUrl: data.imageUrl,
        fileName: data.fileName,
        publishDate: data.publishDate,
        categoryName: data.categoryName
    });
}

export async function clearNews() {
    await db().news.clear();
}

export async function getNewsByCategoryName(categoryName) {
    let list = db().news.orderBy('viewCount').filter((c) => c.categoryName === categoryName);
    return await list.toArray();
}

export async function updateNewsViewCount(newsId, categoryName) {
    let results = db().news.orderBy('viewCount').filter(function (c) {
        return c.categoryName === categoryName;
    });
    var maxItem = await results.last();
    await db().news.update(newsId, {
        viewCount: maxItem.viewCount + 1
    });
}

export async function getNews() {
    return await db().news.orderBy('id').toArray();
}

export default {
    addNews,
    clearNews,
    getNewsByCategoryName,
    updateNewsViewCount,
    getNews
}