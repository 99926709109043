import { getDb as db } from "../index";

export async function addContent(data) {
    await db().contents.put({
        id: data.id,
        categoryId: data.categoryId,
        viewCount: data.viewCount,
        data: data
    });

}

export async function clearContents() {
    await db().contents.clear();
}

export async function deleteContentById(id) {
    await db().contents.delete(id);
}

export async function getContentById(id) {
    return await db().contents.get(id);
}

export async function getContents() {
    return await db().contents.orderBy('viewCount').toArray();
}

export async function getContentByCategoryId(categoryId) {
    let results = await db().contents.orderBy('id').desc().filter(function (c) {
        return c.categoryId === categoryId;
    });
    return await results.toArray();
}

export async function getFirstContentOrderByViewCount(categoryId) {
    let results = db().contents.orderBy('viewCount').filter(function (c) {
        return c.categoryId === categoryId;
    });
    return await results.first();
}

export async function updateContentViewCount(contentId, categoryId) {
    let results = db().contents.orderBy('viewCount').filter(function (c) {
        return c.categoryId === categoryId;
    });
    var maxItem = await results.last();
    await db().contents.update(contentId, {
        viewCount: maxItem.viewCount + 1
    });
}
export default {
    addContent,
    clearContents,
    deleteContentById,
    getContentById,
    getContents,
    getContentByCategoryId,
    updateContentViewCount,
    getFirstContentOrderByViewCount
}