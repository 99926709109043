import storage from "../../storage/modules/news";
import cacheHelper from "../../shared/cache-helper";
import utility from "../../shared/utils";

const state = {
  description: "",
  imageUrl: ""
};

const mutations = {
  setState(state, data) {
    state.description = data.description;
    state.imageUrl = data.fileName;
  }
};

const actions = {
  getCategoryNameById(context, categoryId) {
    let categoryName = "";
    if (categoryId === 45) categoryName = "spor";
    else if (categoryId === 46) categoryName = "guncel";
    else if (categoryId === 47) categoryName = "dunya";
    else if (categoryId === 48) categoryName = "saglik";
    else if (categoryId === 49) categoryName = "yasam";
    else if (categoryId === 50) categoryName = "bilim";
    else if (categoryId === 51) categoryName = "kultur";
    else if (categoryId === 52) categoryName = "ekonomi";
    return categoryName;
  },
  getFileNameByCategoryName(context, categoryName) {
    if (categoryName === "spor") return "NewsSport";
    if (categoryName === "guncel") return "NewsAgenda";
    if (categoryName === "dunya") return "NewsWorld";
    if (categoryName === "saglik") return "NewsHealth";
    if (categoryName === "yasam") return "NewsLife";
    if (categoryName === "bilim") return "NewsScience";
    if (categoryName === "kultur") return "NewsCulture";
    if (categoryName === "ekonomi") return "NewsEconomy";
    if (categoryName === "magazin") return "NewsMagazin";
    return "NewsAgenda";
  },
  async setNewsByCategoryId({ dispatch, commit }, categoryId) {
    let categoryName = await dispatch("getCategoryNameById", categoryId);
    
    let list = await storage.getNewsByCategoryName(categoryName);
    
    if (!list || !list.length) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    let item = null;
    try {
      await utility.asyncForEach(list, async f => {
        let cacheExists = await cacheHelper.exist("/" + f.fileName);
        if (f.description && cacheExists) {
          item = f;
          throw 'found';
        }
      });
    } catch (e) {
      //if (e !== 'found') throw e;
    }
    if (!item) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }

    await storage.updateNewsViewCount(item.id , categoryName);

    let fileName = await dispatch(
      "getFileNameByCategoryName",
      item.categoryName
    );
    commit("setState", item);
    dispatch("play", fileName, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
