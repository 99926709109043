function padRight(str, pad) {
  str = "" + str;
  return pad.substring(0, pad.length - str.length) + str;
}

function toEnglishString(str) {
  return str
    .replace("Ö", "Ö")
    .replace("Ğ", "G")
    .replace("Ç", "C")
    .replace("Ş", "S")
    .replace("İ", "I")
    .replace("Ü", "U")
    .replace("ö", "o")
    .replace("ğ", "g")
    .replace("ç", "c")
    .replace("ş", "s")
    .replace("ı", "i")
    .replace("ü", "u");
}

export default { padRight, toEnglishString };
