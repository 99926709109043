import http from "../../shared/http";
import prayerTimeStorage from '../../storage/modules/prayer-time';
import store from "../../store/index";

export async function downloadPrayerTimes(cityId) {
    var prayerTimes = await http.get(`prayertimes/getlistbycityid?cityId=${cityId}`);
    if (prayerTimes) {
        await prayerTimeStorage.clearPrayerTimes();
        await prayerTimeStorage.bulkAddPrayerTime(prayerTimes);
        await store.dispatch("prayerTime/startTimer");
    }
}

export default {
    downloadPrayerTimes
}