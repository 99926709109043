const state = {
  id: 0,
  title: "",
  image: "",
  gender: "",
  checkInId: "",
  list: []
};

const mutations = {
  setState(state, data) {
    state.id = data.id;
    state.title = data.title;
    state.image = data.image;
    state.gender = data.gender;
    state.checkInId = data.checkInId;
  },
  setList(state, data) {
    if (data && data.list)
      data.list.forEach(checkin => {
        state.list.push(checkin);
      });
  },
  removeItemFromList(state) {
    state.list.splice(0, 1);
  }
};

const actions = {
  async setContent({ dispatch, commit }, content) {
    if (!content || !content.id || !content.image) return;

    commit("setState", {
      id: content.id,
      title: content.title || '-',
      image: content.image,
      gender: content.gender,
      checkInId: content.checkInId
    });
    commit("removeItemFromList");
    dispatch("play", "FoursquareCheckin", { root: true });


  },
  async addToList({ dispatch, commit }, list) {
    commit("setList", {
      list: list
    });
    dispatch("showNextCheckin");
  },
  showNextCheckin({ dispatch }) {
    var data = state.list;
    if (!data || !data.length) {
      dispatch("setNextPlaylist", null, { root: true });
      return;
    }
    dispatch("setContent", data[0]);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
