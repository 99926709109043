import { getDb as db } from "../index";

export async function addAdvertisement(data) {
    data.id = await getMaxId();
    await db().advertisements.put(data);
}

export async function clearAdvertisements() {
    await db().advertisements.clear();
}

export async function getAdvertisements() {
    return await db().advertisements.orderBy('id').toArray().reverse();
}

export async function getFirstAdvertisementOrderById() {
    let results = db().advertisements.orderBy('id');
    return await results.last();
}

export async function getMaxId() {
    let results = await db().advertisements.orderBy('id');
    if (results) {
        var last = await results.last();
        if (last) {
            return last.id + 1;
        }
    }
    return 1;
}

export async function deleteAdvertisement() {
    var id = 1;
    let results = await db().advertisements.orderBy('id');
    if (results) {
        var last = await results.last();
        if (last) {
            id = last.id;
        }
    }
    await db().advertisements
        .where("id").notEqual(id)
        .delete();
}

export default {
    addAdvertisement,
    clearAdvertisements,
    getAdvertisements,
    getFirstAdvertisementOrderById,
    getMaxId,
    deleteAdvertisement
}