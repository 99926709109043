import http from "../../shared/http";
import accountClientStorage from '../../storage/modules/account-client';
import store from "../../store/index";
import jwtHelper from "../../shared/jwt-helper";

export async function downloadAccountClient() {
  const accountClient = await http.get(`accountclients/getbyuserid?userId=${jwtHelper.getUserId()}`);
  if (accountClient) {
    await accountClientStorage.clearAccountClients();
    await accountClientStorage.addAccountClient(accountClient);
    await store.dispatch("loadAccountClient");
  }
}

export default {
  downloadAccountClient
}