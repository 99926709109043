import storage from "../../storage/modules/pharmacy";
const state = {
  pharmacyList: []
};

const mutations = {
  setPharmacies(state, list) {
    state.pharmacyList = list;
  }
};

const actions = {
  async loadPharmacies(context) {
    let list = await storage.getPharmacyByDistrictId()
      if (list) {
        context.commit("setPharmacies", list);
      }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
