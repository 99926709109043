import http from "../../shared/http";
import scoreboardStorage from '../../storage/modules/scoreboard';
import { checkPlaylistByCategoryName } from './playlist-manager';
import store from "../../store/index";

export async function downloadScoreboard() {
    if (!(await checkPlaylistByCategoryName("Puan Durumu"))) {
        await scoreboardStorage.clearScoreboards();
        return;
    }
    let scoreboards = await http.get("scoreboards/getlistbyleagueid?leagueId=1");
    if (scoreboards) {
        await scoreboardStorage.clearScoreboards();
        await scoreboardStorage.bulkAddScoreBoard(scoreboards);
        await store.dispatch("scoreboard/loadScoreboard");
    }
}

export default {
    downloadScoreboard
}