
import http from "../../shared/http";
import footerHeaderStorage from '../../storage/modules/footer-header-cotent';
import store from "../../store/index";

export async function downloadFooterHeaderContents() {
    let list = await http.get(`footerheadercontents/getlist`)
    if (list) {
        await footerHeaderStorage.clearFooterHeaderContents();
        await footerHeaderStorage.bulkAddFooterHeaderContent(list);
        await store.dispatch("footerBar/loadContents");
        await store.dispatch("headerBar/loadContents");
    }
}

export default {
    downloadFooterHeaderContents
}