import dateHelper from "../../shared/date-helper";
import storage from "../../storage/modules/footer-header-cotent";

const state = {
  currentIndex: 0,
  footerTexts: [""],
  time: dateHelper.getCurrenctTimeString()
};

const getters = {
  footerTextAsString: state => {
    let text = "";
    if (state.footerTexts) state.footerTexts.forEach(t => (text += t + " | "));
    return text;
  },
  currentText: state => {
    if (
      state.currentIndex < 0 ||
      state.currentIndex >= state.footerTexts.length
    )
      return "";
    return state.footerTexts[state.currentIndex];
  }
};

const mutations = {
  setTime(state, time) {
    state.time = time;
  },
  setFooterTexts(state, footerTexts) {
    state.footerTexts = footerTexts;
  },
  setCurrentIndex(state, currentIndex) {
    state.currentIndex = currentIndex;
  }
};

const actions = {
  startUpdateTimeInterval(context) {
    setInterval(
      () => context.commit("setTime", dateHelper.getCurrenctTimeString()),
      12000
    );
  },
  loadContents(context) {
    storage.getFooterHeaderContentsByType(2).then(list => {
      if (list && list.length > 0) {
        context.commit(
          "setFooterTexts",
          list.map(u => u.title)
        );
      } else {
        context.commit("setFooterTexts", ["HOŞGELDİNİZ"]);
      }
    });
  },
  setNextText({ dispatch, commit, state }) {
    if (!state.footerTexts || !state.footerTexts.length) return;
    commit("setCurrentIndex", state.currentIndex + 1);
    if (state.currentIndex >= state.footerTexts.length) {
      commit("setCurrentIndex", -1);
      dispatch("setNextText");
      return;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
