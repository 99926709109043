<template>
  <router-view></router-view>
</template>

<script>
import utility from "./shared/utils";
import store from "./store/index";
import contentHelper from "./shared/content-helper";
export default {
  name: "app",
  data() {
    return {
      screenShotTimer: null,
      oneTimeTimer: null,
      checkVersionTimer: null,
      emailOrUserName: "",
      password: "",
    };
  },
  created() {
    try {
      this.screenShotTimer = setInterval(() => {
        utility.takeSnapShot();
      }, process.env.VUE_APP_SCREEN_SHOT_TIME);
      this.oneTimeTimer = setInterval(async () => {
        var oneTimeContent = await contentHelper.getOneTimeContent();
        if (
          oneTimeContent &&
          localStorage.getItem("deactivateContentId") != oneTimeContent.id
        ) {
          store.dispatch("content/prepareContent", oneTimeContent);
          localStorage.setItem("deactivateContentId", oneTimeContent.id);
        }
      }, 5000);

      this.checkVersionTimer = setInterval(() => {
        window.console.log('Checking for last version ....');
        utility.checkVersion();
      }, process.env.VUE_APP_CHECK_VERSION_TIME);
    } catch (error) {
      window.console.log(error);
    }
  },
  beforeDestroy() {
    if (this.screenShotTimer)
      clearInterval(this.screenShotTimer);
    if (this.oneTimeTimer)
      clearInterval(this.oneTimeTimer);
    if (this.checkVersionTimer)
      clearInterval(this.checkVersionTimer);
  }
};
</script>

<style lang="scss">
@import "~bootstrap";
@import "~bootstrap-vue";
@import "assets/scss/site.scss";
</style>
