import http from "../../shared/http";
import playlistStorage from '../../storage/modules/playlist';
import store from "../../store/index";

export async function downloadPlaylists() {
  const playlists = await http.get("playlists/getlistforclient");
  if (playlists) {
    const oldPlaylists = await playlistStorage.getPlaylists();
    if (oldPlaylists && oldPlaylists.length) {
      await playlistStorage.clearPlaylists();
    }
    await playlistStorage.bulkAddPlaylist(playlists);
    await store.dispatch("loadPlaylists");
  }
}

export async function checkPlaylistByCategoryName(categoryName) {
  let playlists = await playlistStorage.getPlaylists();
  if (!playlists || !playlists.length)
    return false;
  let filteredList = playlists.filter(u =>
    u.data.categoryName.includes(categoryName)
  );
  return filteredList && filteredList.length > 0;
}

export default {
  downloadPlaylists,
  checkPlaylistByCategoryName
}














/* 
export function downloadPlaylists() {
  return http.get("playlists/getlistforclient").then(async playlists => {
    if (playlists) {
      const oldPlaylists = await playlistStorage.getPlaylists();
      if (oldPlaylists && oldPlaylists.length) {
        await utility.asyncForEach(playlists, async p => {
          let filteredList = oldPlaylists.filter(u =>
            u.categoryId == p.categoryId
          );
          if (filteredList && filteredList.length > 0) {
            await playlistStorage.deletePlaylistByCategoryId(p.categoryId, p.orderNumber);
          }
          await playlistStorage.addPlaylist(p);
        });
      } else {
        await playlistStorage.bulkAddPlaylist(playlists);
      }
      const result2 = oldPlaylists.filter(function (o1) {
        return !playlists.some(function (o2) {    //  for diffrent we use NOT (!) befor obj2 here
          return o1.categoryId == o2.categoryId && o1.orderNumber == o2.orderNumber;          // id is unnique both array object
        });
      });
      if (result2 && result2.length) {
        await utility.asyncForEach(result2, async p => {
          await playlistStorage.deletePlaylistByCategoryId(p.categoryId, p.orderNumber);
        });
      }
      await store.dispatch("loadPlaylists");
      return true;
    }
    return false;
  });
}
*/