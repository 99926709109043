import http from "../../shared/http";
import instagramStorage from '../../storage/modules/instagram';
import utility from "../../shared/utils";
import cacheHelper, { addFileToCache } from "../../shared/cache-helper";
import { checkPlaylistByCategoryName } from './playlist-manager'

export async function downloadInstagramContents() {
        if (!(await checkPlaylistByCategoryName("Instagram"))) {
            await instagramStorage.clearInstagramContents();
            await clearUnUsedInstagramContentCaches();
            return;
        }
        var contents = await http.get("instagramContents/getactivelist");
        if (contents) {
            let oldList = await instagramStorage.getInstagramContents();
            await instagramStorage.clearInstagramContents();
            await utility.asyncForEach(contents, async c => {
                let filteredList = oldList
                    ? oldList.filter(u => u.mediaType === c.mediaType)
                    : null;
                let minViewCount =
                    filteredList && filteredList.length ? filteredList[0].viewCount : 0;

                let oldItem =
                    oldList && oldList.length ? oldList.find(u => u.mediaId == c.mediaId) : null;
                c.viewCount = oldItem ? oldItem.viewCount : minViewCount;
                await instagramStorage.addInstagramContent(c);
            });
            let contentList = await instagramStorage.getInstagramContents();
            await clearUnUsedInstagramContentCaches(contentList);
            await saveInstagramContentsToCache(contentList);
        }
}


export async function saveInstagramContentsToCache(contents) {
    if (!contents || !contents.length) return;
    return utility.asyncForEach(contents, async c => {
        if (c && c.mediaUrl) {
            await addFileToCache(process.env.VUE_APP_BASE_CDN_URL + "/" + c.mediaUrl);
        }
        if (c && c.profileImageUrl) {
            await addFileToCache(process.env.VUE_APP_BASE_CDN_URL + "/" + c.profileImageUrl);
        }
    });
}

export async function clearUnUsedInstagramContentCaches(contents) {
    let fileNames = [];
    if (contents && contents.length) {
        await utility.asyncForEach(contents, async c => {
            if (c) {
                if (c.mediaUrl && c.mediaUrl.length) {
                    c.mediaUrl = c.mediaUrl.replace(/\\/g, "/");
                    c.mediaUrl = c.mediaUrl.replaceAll("//", "/");
                    fileNames.push(process.env.VUE_APP_BASE_CDN_URL + "/" + c.mediaUrl);
                }
                if (c.profileImageUrl && c.profileImageUrl.length) {
                    c.profileImageUrl = c.profileImageUrl.replace(/\\/g, "/");
                    c.profileImageUrl = c.profileImageUrl.replaceAll("//", "/");
                    if (fileNames.indexOf(c.profileImageUrl) === -1) {
                        fileNames.push(process.env.VUE_APP_BASE_CDN_URL + "/" + c.profileImageUrl);
                    }
                }
            }
        });
    }

    let cacheList = await cacheHelper.getAllFiles();
    if (cacheList) {
        await utility.asyncForEach(cacheList, async f => {
            if (f.url.indexOf("Resources/Instagram") > -1
                && !fileNames.includes(f.url)) {
                await cacheHelper.deleteByKey(f.url);
            }
        });
    }
}

export default {
    downloadInstagramContents
}