import { getDb as db } from "../index";

export function addPrayerTime(data) {
    return db().prayerTimes.put({
        id: data.id,
        cityId: data.cityId,
        date: data.date,
        dawnTime: data.dawnTime,
        sunTime: data.sunTime,
        noonPrayer: data.noonPrayer,
        afternoonPrayer: data.afternoonPrayer,
        eveningPrayer: data.eveningPrayer,
        nightPrayer: data.nightPrayer,
        lastUpdateDate: data.lastUpdateDate
    });
}

export async function bulkAddPrayerTime(list){
    var items = list.map(data => ({
        id: data.id,
        cityId: data.cityId,
        date: data.date,
        dawnTime: data.dawnTime,
        sunTime: data.sunTime,
        noonPrayer: data.noonPrayer,
        afternoonPrayer: data.afternoonPrayer,
        eveningPrayer: data.eveningPrayer,
        nightPrayer: data.nightPrayer,
        lastUpdateDate: data.lastUpdateDate
    }));
    await db().prayerTimes.bulkAdd(items);
}

export async function clearPrayerTimes() {
    await db().prayerTimes.clear();
}

export function deletePrayerTimeById(id) {
    return db().prayerTimes.delete(id);
}

export function getPrayerTimeById(id) {
    return db().prayerTimes.get(id);
}

export function getPrayerTimes() {
    return db().prayerTimes.orderBy('id').toArray();
}

export default{
    addPrayerTime,
    clearPrayerTimes,
    deletePrayerTimeById,
    getPrayerTimeById,
    getPrayerTimes,
    bulkAddPrayerTime
}