import { getDb as db } from "../index";

export async function addPlaylist(data) {
    await db().playlists.put({
        id: data.id,
        categoryId: data.categoryId,
        orderNumber: data.orderNumber,
        data: data
    });
}

export async function bulkAddPlaylist(list) {
    var playList = list.map(f => ({
        id: f.id,
        categoryId: f.categoryId,
        orderNumber: f.orderNumber,
        data: f
    }));
    return await db().playlists.bulkAdd(playList);
}

export async function clearPlaylists() {
    return await db().playlists.clear();
}

export async function deletePlaylistById(id) {
    await db().playlists.delete(id);
}

export async function deletePlaylistByCategoryId(categoryId, orderNumber) {
    let results = db().playlists.orderBy('id').filter(function (c) {
        return c.categoryId == categoryId && c.orderNumber == orderNumber
    });

    var item = await results.first();
    if (item) {
        await db().playlists.delete(item.id);
    }
}

export async function getPlaylistById(id) {
    return await db().playlists.get(id);
}

export async function getPlaylists() {
    return await db().playlists.orderBy('orderNumber').toArray();
}
export default {
    addPlaylist,
    clearPlaylists,
    deletePlaylistById,
    getPlaylistById,
    getPlaylists,
    bulkAddPlaylist,
    deletePlaylistByCategoryId
}
