import http from "../../shared/http";
import foursquareStorage from '../../storage/modules/foursquare-tip';
import utility from "../../shared/utils";
import cacheHelper, { addFileToCache } from "../../shared/cache-helper";
import { checkPlaylistByCategoryName } from './playlist-manager'

export async function downloadFoursquareTips() {
  let isInPlaylist = await checkPlaylistByCategoryName("Foursquare Yorum");
  if (!isInPlaylist) {
    await foursquareStorage.clearFourSquareTips();
    await clearUnUsedFoursquareTipCaches();
    return;
  }
  let contents = await http.get("fourSquaretips/getactivelist");
  if (contents) {
    const oldList = await foursquareStorage.getFourSquareTips();
    let min = 0;
    if (oldList && oldList.length) {
      min = Math.min.apply(Math, oldList.map(function (o) { return o.viewCount; }));
      await foursquareStorage.clearFourSquareTips();
    }
    await utility.asyncForEach(contents, async c => {
      let oldItem =
        oldList && oldList.length ? oldList.find(u => u.tipId == c.tipId) : null;
      c.viewCount = oldItem ? (oldItem.viewCount || min) : min;
      await foursquareStorage.addFourSquareTip(c);
    });
    const contentList = await foursquareStorage.getFourSquareTips();
    await clearUnUsedFoursquareTipCaches(contentList);
    await saveFoursquareTipsToCache(contentList);
  }
}

export async function saveFoursquareTipsToCache(contents) {
  if (!contents || !contents.length) return;
  await utility.asyncForEach(contents, async c => {
    if (c) {
      if (c.profileImage) await addFileToCache(c.profileImage);
      if (c.image) await addFileToCache(c.image);
    }
  });
}

export async function clearUnUsedFoursquareTipCaches(contents) {
  let fileNames = [];
  if (contents && contents.length) {
    contents.forEach(c => {
      if (c.image) fileNames.push(c.image);
      if (c.profileImage) fileNames.push(c.profileImage);
    });
  }
  let cacheList = await cacheHelper.getAllFiles();
  if (cacheList) {
    await utility.asyncForEach(cacheList, async f => {
      if (f.url.includes("fastly.4sqi.net") && !fileNames.includes(f.url)) {
        await cacheHelper.deleteByKey(f.url);
      }
    });
  }
}

export default {
  downloadFoursquareTips
}