import storage from "../../storage/modules/weather-forecast";
import accountStorage from "../../storage/modules/account";
import dateHelper from "../../shared/date-helper";

const state = {
  weatherIcon: "Cloudy.png",
  weatherDay: "",
  weatherValue: "",
  weatherText: "",
  weatherCity: ""
};

const mutations = {
  setSingleWeather(state, weatherForecast) {
    let dayOfWeek = new Date().getDay();
    state.weatherDay = dateHelper.getDayNameByIndex(dayOfWeek - 1);
    state.weatherValue = weatherForecast.degrees;
    state.weatherCity = weatherForecast.cityName;
    state.weatherText = weatherForecast.forecast;

    if (weatherForecast.forecastKey === "Sunny")
      state.weatherIcon = "Sunny.png";
    else if (weatherForecast.forecastKey === "Snowy")
      state.weatherIcon = "Snowy.png";
    else if (weatherForecast.forecastKey === "LightSnow")
      state.weatherIcon = "Snowy.png";
    else if (weatherForecast.forecastKey === "Rainy")
      state.weatherIcon = "Rainy.png";
    else if (weatherForecast.forecastKey === "HeavyShowers")
      state.weatherIcon = "Rainy.png";
    else if (weatherForecast.forecastKey === "Thunderstorm")
      state.weatherIcon = "Thunderstorm.png";
    else if (weatherForecast.forecastKey === "RainyWithSnow")
      state.weatherIcon = "Snowy.png";
    else if (weatherForecast.forecastKey === "LightRain")
      state.weatherIcon = "Rainy.png";
    else if (weatherForecast.forecastKey === "MostlyCloudy")
      state.weatherIcon = "Cloudy.png";
    else if (weatherForecast.forecastKey === "PartlyCloudy")
      state.weatherIcon = "PartlyCloudy.png";
    else if (weatherForecast.forecastKey === "LessCloudy")
      state.weatherIcon = "PartlyCloudy.png";
    else state.weatherIcon = "PartlyCloudy.png";
  }
};

const actions = {
  async loadWeatherForecasts(context) {
    let accounts = await accountStorage.getAccounts();
    if (!accounts || !accounts[0]) return;
    if (accounts[0].districtId) {
      let listByDistrict = await storage.getWeatherForecastByDistrictId(
        accounts[0].districtId
      );
      if (listByDistrict && listByDistrict[0])
        listByDistrict[0].cityName = listByDistrict[0].districtName;
      context.commit("setSingleWeather", listByDistrict[0]);
    } else if (accounts[0].cityId) {
      let listByCity = await storage.getWeatherForecastsByCityId(
        accounts[0].cityId
      );
      if (listByCity && listByCity[0])
        context.commit("setSingleWeather", listByCity[0]);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
